import { Component, OnInit } from '@angular/core';
import { OrderService } from '../services/orderService';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  clientEmail: string;

  constructor(private orderService: OrderService) { }

  ngOnInit() {
    this.getClientDetail();
  }

  getClientDetail() {
    this.clientEmail = localStorage.getItem('emailId')
    this.orderService.getClientDetails(this.clientEmail).subscribe((data:any) => {})
  }

}
