import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  private emailSubject = new BehaviorSubject<string | null>(null);
  private passwordSubject = new BehaviorSubject<string | null>(null);

  setEmail(email: string) {
    this.emailSubject.next(email);
  }

  getEmail() {
    return this.emailSubject.asObservable();
  }

  setPassword(password: string) {
    this.passwordSubject.next(password);
  }

  getPassword() {
    return this.passwordSubject.asObservable();
  }
}
