import { Component, OnInit, Output,EventEmitter } from "@angular/core";
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
import { OrderService } from '../../services/orderService';
import { ChatService } from '../../services/chatService';

import { DatePipe } from '@angular/common';
import date from 'date-and-time';

@Component({
    selector:'app-chat-console',
    templateUrl:'./chat-console.html',
    styleUrls:['./chat-console.scss']
})

export class ChatConsoleComponent implements OnInit{

    @Output() messageEvent = new EventEmitter<string>();

    chatInput
    orderID
    chatData
    defaultChatValue : string 
    fileDetails:Array<any>
    showLoader
    client_id: any;
    fileArray: any = []
    currentDate: any;
    fileName: any = []
    fileData: any = []
    
    constructor(private orderService:OrderService,private route: ActivatedRoute, private chatService: ChatService){
    }

    ngOnInit(){
        this.defaultChatValue = ''
        this.route.params.subscribe(obj => {
            this.orderID = obj.id
        })
        this.fileDetails=[]

        this.orderService.getOrderDetails(this.orderID).subscribe(data => {
            this.client_id = data['client_id']
        })

        this.dateTimeValue()
    }

    dateTimeValue() {
        const now = new Date()
        var utcDate = date.format(now, 'YYYY-MM-DD HH:mm:ss', true);
        utcDate = new Date(utcDate);
        utcDate.setHours(utcDate.getHours() - 5)
        this.currentDate = utcDate
    }

    onSubmit(form: NgForm) {
        // if (form.value.chatInput !== "" && form.value.chatInput !== "↵") {
            if ((form.value.chatInput !== "" && form.value.chatInput !== "↵") || (form.value.chatInput === "" && this.fileDetails.length > 0)) {
            this.chatData = form.value
            this.chatData.files = this.fileDetails
            this.chatData.type = 'client'
            this.saveData()
            this.messageEvent.emit(this.chatData)
            this.defaultChatValue = ''
        }
    }

    saveData() {
        
        if(this.fileName != undefined){
            var typeF = this.fileName
        }else{
            typeF = 'text'
        }
        const messageData = {
            order_id: this.orderID,
            client_id: this.client_id,
            user_id: this.orderID,
            fileData: this.fileArray,
            messages : [{
                text: this.chatData.chatInput,
                type:typeF,
                sender:"client",
                created_at:null,
                updated_at:null,
                isSeen:false,
                isDelivered:false
            }]
        }
        console.log('data : ', messageData)
        this.chatService.sendMessage(messageData, this.client_id, this.orderID);
        this.fileDetails = []
        this.fileArray = []
        this.fileName = []
    }
    
    uploadFile(event) {
        this.showLoader = true
        var newFile = event.files[0];
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(newFile);
        this.orderService.fileUpload(newFile).subscribe(obj => {
            this.fileName.push(obj['key'])
            var datePipe = new DatePipe("en-US");
            var filedata = {
                file_name: obj['key'],
                file_sender: "client",
                file_sender_id: this.client_id,
                created_at: datePipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss'),
                updated: datePipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss')
            }
            this.fileArray.push(filedata)
            this.fileDetails.push(obj)
            this.showLoader = false
        })
    }

    removeFile(data){
        this.fileArray.splice(data,1)
        this.fileName.splice(data,1)
        this.fileDetails.splice(data,1)
    }
}