import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../services/orderService';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  clientEmail: string;

  constructor(private orderService: OrderService) { }

  ngOnInit() {
    this.getClientDetail();
  }

  getClientDetail() {
    this.clientEmail = localStorage.getItem('emailId')
    this.orderService.getClientDetails(this.clientEmail).subscribe((data:any) => {})
  }

}
