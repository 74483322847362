import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';
import { OrderService } from '../../services/orderService';
import orderType from '../../services/orderTypeService';
import CountyNames from '../../services/countyNamesService';
import { Router } from '@angular/router';
import { CurrencyPipe, formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { isArray } from 'util';
declare var toastr: any;
declare var $: any;
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import date from 'date-and-time';
import { LoginService } from '../../services/loginService';

@Component({
    selector: 'app-single-order',
    templateUrl: './single-order.component.html',
    styleUrls: ['./single-order.component.scss']
})
export class SingleOrderComponent implements OnInit, OnChanges {

    product_type: any;
    client_order_no: string;
    address: string;
    county: string;
    state: string;
    zip_code: number;
    parcel_ids: any;
    purpose: string;
    projected_close_date: string;
    pricing: any;
    duebyDate
    borrower_names: Array<any>;
    seller_names: Array<any>;
    showSeller: boolean;
    loan_amount: string;
    purchase_amount: string;
    comments: any;
    attachments: any;
    comment: string;

    order_type: any;
    hideOrderProducts;

    product_type_options = orderType.types();
    county_name_list = CountyNames.names();
    unique_county_names = CountyNames.uniqueCountyNames();
    purpose_list: Array<any>;

    fileDetails: Array<any> = [];
    showLoader;
    stateList: Array<any>;
    selectedStateItems: Array<any>;
    selectedCountyItems: Array<any>;
    selectedPurposeItems: Array<any>;
    selectedPurpose;
    settings;
    settings2;
    settings3;

    productTypeRequiredError: boolean;
    projectedCloseDateError: boolean;
    borrowerNamesError: boolean;
    sellerNamesError: boolean;
    projectedCloseDate: boolean;
    borrowerNames: boolean;
    sellerNames: boolean;
    countyRequiredError: boolean;
    stateRequiredError: boolean;
    purchaseRequiredError: boolean;
    purposeRequiredError: boolean;
    enablePurpose: boolean;
    purposeOpen: boolean;

    pricing_value: number;
    loan_amount_value: string;
    purchase_amount_value: string;

    countyOpen: boolean;
    stateOpen: boolean;
    productTypeOpen: boolean
    productRequiredError: boolean
    productType:any =  []
    selectedProductTypes
    lenderOpen: boolean
    lenderNames: any = []
    lenderRequiredError: boolean
    settings4
    settings5
    client_id: any;
    clientEmail: string;
    clientId: any;
    selectedLender: any = [];

    countries = CountyNames.names()
    counties: any = []
    OrderPrice
    fileArray: any = []
    selectedPriority = [{value: 'Normal'}]
    priorities: any = [{value: 'Normal'}, {value: 'Rush'}]
    settings8: { text: string; selectAllText: string; unSelectAllText: string; classes: string; enableSearchFilter: boolean; singleSelection: boolean; primaryKey: string; labelKey: string; };
    clientName: any;
    productname: any;
    currentDate: any;
    ParcelIds: any = []
    hours: any;
    mins: any;
    pricing_input: boolean
    constructor(private orderService: OrderService, private ngxUILoader: NgxUiLoaderService, private router: Router, private currency: CurrencyPipe,private loginService: LoginService,
        public datepipe: DatePipe) {
        this.showLoader = false;
        this.borrower_names = [];
        this.seller_names = [];
        // this.parcel_ids = [];
        this.showSeller = false;
        this.selectedPriority = [{value: 'Normal'}]
        this.stateList = [
            {
                'name': 'Alabama',
                'abbreviation': 'AL'
            },
            {
                'name': 'Alaska',
                'abbreviation': 'AK'
            },
            {
                'name': 'American Samoa',
                'abbreviation': 'AS'
            },
            {
                'name': 'Arizona',
                'abbreviation': 'AZ'
            },
            {
                'name': 'Arkansas',
                'abbreviation': 'AR'
            },
            {
                'name': 'California',
                'abbreviation': 'CA'
            },
            {
                'name': 'Colorado',
                'abbreviation': 'CO'
            },
            {
                'name': 'Connecticut',
                'abbreviation': 'CT'
            },
            {
                'name': 'Delaware',
                'abbreviation': 'DE'
            },
            {
                'name': 'District Of Columbia',
                'abbreviation': 'DC'
            },
            {
                'name': 'Federated States Of Micronesia',
                'abbreviation': 'FM'
            },
            {
                'name': 'Florida',
                'abbreviation': 'FL'
            },
            {
                'name': 'Georgia',
                'abbreviation': 'GA'
            },
            {
                'name': 'Guam',
                'abbreviation': 'GU'
            },
            {
                'name': 'Hawaii',
                'abbreviation': 'HI'
            },
            {
                'name': 'Idaho',
                'abbreviation': 'ID'
            },
            {
                'name': 'Illinois',
                'abbreviation': 'IL'
            },
            {
                'name': 'Indiana',
                'abbreviation': 'IN'
            },
            {
                'name': 'Iowa',
                'abbreviation': 'IA'
            },
            {
                'name': 'Kansas',
                'abbreviation': 'KS'
            },
            {
                'name': 'Kentucky',
                'abbreviation': 'KY'
            },
            {
                'name': 'Louisiana',
                'abbreviation': 'LA'
            },
            {
                'name': 'Maine',
                'abbreviation': 'ME'
            },
            {
                'name': 'Marshall Islands',
                'abbreviation': 'MH'
            },
            {
                'name': 'Maryland',
                'abbreviation': 'MD'
            },
            {
                'name': 'Massachusetts',
                'abbreviation': 'MA'
            },
            {
                'name': 'Michigan',
                'abbreviation': 'MI'
            },
            {
                'name': 'Minnesota',
                'abbreviation': 'MN'
            },
            {
                'name': 'Mississippi',
                'abbreviation': 'MS'
            },
            {
                'name': 'Missouri',
                'abbreviation': 'MO'
            },
            {
                'name': 'Montana',
                'abbreviation': 'MT'
            },
            {
                'name': 'Nebraska',
                'abbreviation': 'NE'
            },
            {
                'name': 'Nevada',
                'abbreviation': 'NV'
            },
            {
                'name': 'New Hampshire',
                'abbreviation': 'NH'
            },
            {
                'name': 'New Jersey',
                'abbreviation': 'NJ'
            },
            {
                'name': 'New Mexico',
                'abbreviation': 'NM'
            },
            {
                'name': 'New York',
                'abbreviation': 'NY'
            },
            {
                'name': 'North Carolina',
                'abbreviation': 'NC'
            },
            {
                'name': 'North Dakota',
                'abbreviation': 'ND'
            },
            {
                'name': 'Northern Mariana Islands',
                'abbreviation': 'MP'
            },
            {
                'name': 'Ohio',
                'abbreviation': 'OH'
            },
            {
                'name': 'Oklahoma',
                'abbreviation': 'OK'
            },
            {
                'name': 'Oregon',
                'abbreviation': 'OR'
            },
            {
                'name': 'Palau',
                'abbreviation': 'PW'
            },
            {
                'name': 'Pennsylvania',
                'abbreviation': 'PA'
            },
            {
                'name': 'Puerto Rico',
                'abbreviation': 'PR'
            },
            {
                'name': 'Rhode Island',
                'abbreviation': 'RI'
            },
            {
                'name': 'South Carolina',
                'abbreviation': 'SC'
            },
            {
                'name': 'South Dakota',
                'abbreviation': 'SD'
            },
            {
                'name': 'Tennessee',
                'abbreviation': 'TN'
            },
            {
                'name': 'Texas',
                'abbreviation': 'TX'
            },
            {
                'name': 'Utah',
                'abbreviation': 'UT'
            },
            {
                'name': 'Vermont',
                'abbreviation': 'VT'
            },
            {
                'name': 'Virgin Islands',
                'abbreviation': 'VI'
            },
            {
                'name': 'Virginia',
                'abbreviation': 'VA'
            },
            {
                'name': 'Washington',
                'abbreviation': 'WA'
            },
            {
                'name': 'West Virginia',
                'abbreviation': 'WV'
            },
            {
                'name': 'Wisconsin',
                'abbreviation': 'WI'
            },
            {
                'name': 'Wyoming',
                'abbreviation': 'WY'
            }
        ];
        this.selectedStateItems = [];
        this.selectedCountyItems = [];
        this.selectedPurposeItems = [];

        this.purpose_list = [
            { 'type': 'Refinance' },
            { 'type': 'Purchase' }
        ];
        this.settings = {
            text: 'Select State',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            classes: 'myclass custom-class',
            enableSearchFilter: true,
            singleSelection: true,
            primaryKey: 'abbreviation',
            labelKey: 'abbreviation',
        };
        this.settings2 = {
            text: 'Select County',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            classes: 'myclass custom-class',
            enableSearchFilter: true,
            singleSelection: true,
            primaryKey: 'County',
            labelKey: 'County',
        };
        this.settings3 = {
            text: 'Select Transaction type',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            classes: 'myclass custom-class',
            enableSearchFilter: false,
            singleSelection: true,
            primaryKey: 'type',
            labelKey: 'type',
        };
        this.settings4 = {
            text: 'Select Product Type',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            classes: 'myclass custom-class',
            enableSearchFilter: true,
            singleSelection: true,
            primaryKey: "_id",
            labelKey: "product_name",
        };
        this.settings5 = {
            text: 'Select Lender',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            classes: 'myclass custom-class',
            enableSearchFilter: true,
            singleSelection: true,
            primaryKey: "_id",
            labelKey: "name",
        };
        this.settings8 = {
            text: 'Select Order Priority',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            classes: 'myclass custom-class',
            enableSearchFilter: true,
            singleSelection: true,
            primaryKey: "value",
            labelKey: "value",
        };

        this.productTypeRequiredError = false;
        this.projectedCloseDateError = false;
        this.borrowerNamesError = false;
        this.sellerNamesError = false;
        this.stateRequiredError = false;
        this.countyRequiredError = false;
        this.purposeRequiredError = false;
        this.pricing_input = false
        this.dateTimeValue();
    }

    @Input() productName;


    options: DatepickerOptions = {
        minYear: 1970,
        maxYear: 2030,
        displayFormat: 'MMM D[,] YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
        barTitleIfEmpty: 'Click to select a date',
        placeholder: '', // HTML input placeholder attribute (default: '')
        fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
        useEmptyBarTitle: false, // Defaults to true.
        // If set to false then barTitleIfEmpty will be
        // disregarded and a date will always be shown
        minDate: new Date()
    };

    ngOnInit() {
        this.hideOrderProducts = true;
        this.enablePurpose = false;
        this.getClientDetails();
    }

    ngOnChanges(productName) {}

    dateTimeValue() {
        const now = new Date()
        var utcDate = date.format(now, 'YYYY-MM-DD HH:mm:ss', true);
        // console.log('Time Now : ',utcDate);
        utcDate = new Date(utcDate);
        utcDate.setHours(utcDate.getHours() - 5)
        this.currentDate = utcDate
        // console.log('Time : ',this.currentDate);
        // console.log('Date time : ', this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss'))
    }

    getClientDetails(){
        this.clientEmail = localStorage.getItem('emailId')
        this.orderService.getClientDetails(this.clientEmail).subscribe((data:any) => {
          this.clientId = data.client._id
        //   console.log('Cleint data  : ',data);
        //   console.log('Data1 : ',this.clientId);
        this.clientName = data.client.companyName
          this.getProductdata(this.clientId);
          this.getLender(this.clientId);
        })
      }


    toggleProductTypeSelection() {
        this.hideOrderProducts = !this.hideOrderProducts;
    }

    hideOrderProductsOnEsc(event) {
        this.hideOrderProducts = true;
    }

    selectedProductType(selected_product_type: string) {
        this.productTypeRequiredError = false;
        this.hideOrderProducts = true;
        this.product_type = selected_product_type;
        if (selected_product_type === 'Full_Search' || selected_product_type === 'Full_Search_Plus') {
            this.showSeller = true;
        } else {
            this.showSeller = false;
        }
        // console.log(this.product_type);
    }

    enablePurchaseAmount(event) {
        this.purposeRequiredError = false;
        this.purposeOpen = true;
        this.purchaseRequiredError = false;
        this.selectedPurpose = this.selectedPurposeItems[0].type;
        if (this.selectedPurpose === 'Purchase') {
            this.enablePurpose = true;
        } else {
            this.enablePurpose = false;
        }
        return;
    }

    onSubmit(form: NgForm) {
        const formdata = form.value;
        const Arr = Object.keys(form.value);
        // console.log('pricingpricingpricingpricing',this.pricing);
        // console.log('Form Data Array : ',Arr);
        // console.log('File data : ', this.fileArray);
        // console.log("this.pricing.lenght----------- ",formdata.pricing)
        let validationFlag = false;
        Arr.forEach(key => {
            if (formdata[key] === undefined || formdata[key].length === 0) {

                if (validationFlag === false) {
                    if(this.selectedProductTypes === undefined){
                        this.productRequiredError = true;
                    }else{
                        this.productRequiredError = false;
                    }

                    if (this.selectedCountyItems.length === 0) {
                        this.countyRequiredError = true;
                    } else {
                        this.countyRequiredError = false;
                    }
                    if (this.selectedStateItems.length === 0) {
                        this.stateRequiredError = true;
                    } else {
                        this.stateRequiredError = false;
                    }
                    if (this.selectedPurposeItems.length === 0) {
                        this.purposeRequiredError = true;
                        validationFlag = true;
                    } else {
                        this.purposeRequiredError = false;
                    }
                    
                    // if(this.selectedLender.lenght === 0){
                    //     this.lenderRequiredError = true;
                    // }else{
                    //     this.lenderRequiredError = false;
                    // }
                    // if (!form.value.projected_close_date || form.value.projected_close_date === '') {
                    //     this.projectedCloseDateError = true;
                    //     // toastr.error("Please select project close date")
                    // }

                    // if (!form.value.borrower_names || form.value.borrower_names === '') {
                    //     this.borrowerNamesError = true;
                    //     // toastr.error("Please enter atleast a borrower name")
                    // }

                    if (this.showSeller) {
                        if (!form.value.seller_names || form.value.seller_names === '') {
                            this.sellerNamesError = true;
                            toastr.error('Please enter atleast a seller name');
                        }
                    }
                    // if(['projected_close_date', 'purchase_amount', 'comments'].indexOf(key) < 0){
                    //     toastr.error('Fill the required  field - ' + key.split('_').join(' '));
                    //     validationFlag = true;
                    // }
                }
            }
        });
        if (validationFlag) {
            return;
        }

        // if (formdata.pricing === '') {
        //     return;
        // }


        // console.log(form.value);
        // if (!form.value.zip_code || form.value.zip_code.length < 5 || form.value.zip_code.length > 11) {
        //     toastr.error('Zip code should be atleast 5 digits');
        //     return;
        // }
        // if(form.value.loan_amount ){
        //     if (form.value.loan_amount.length > 11) {
        //         toastr.error('Loan amount is too large');
        //         return;
        //     }
        // }

        // if(form.value.purchase_amount) {
        //     if (form.value.purchase_amount && form.value.purchase_amount.length > 11) {
        //         toastr.error('Purchase amount is too large');
        //         return;
        //     }
        // }

        // if (isNaN(parseInt(form.value.loan_amount, 10))) {
        //     toastr.error('enter the loan ammount in numbers');
        //     return;
        // }

        // if (isNaN(parseInt(form.value.purchase_amount, 10))) {
        //     toastr.error('enter the purchase ammount in numbers');
        //     return;
        // }
        // console.log('Form Data :', form.value);\
        // console.log('Parcel Id : ',form.value.parcelIDs)
        var pId = form.value.parcelIDs.split(',')
        // console.log('Parcel Id Sep : ',pId)
        for (var i = 0; i<pId.length; i++){
            this.ParcelIds.push(pId[i])
        }
        // console.log('Parcel Id Arr : ', this.ParcelIds)
        const data = {
            product_id: this.selectedProductTypes[0]._id,
            product_type: null,
            client_order_no: form.value.client_order_no,
            status: "Open",
            client_id: this.clientId,
            client_name: this.clientName,
            product_name: this.productname,
            order_priority: this.priorities[0].value,
            address: form.value.address,
            zip_code: form.value.zip_code,
            county: this.selectedCountyItems[0].County,
            state: this.selectedStateItems[0].name,
            parcel_ids: this.ParcelIds,
            lender_id: this.selectedLender[0] ? this.selectedLender[0]._id : null,
            purpose: this.selectedPurpose,
            buyer_names: form.value.borrower_names,
            seller_names: form.value.seller_names,
            // loan_amount: parseInt(this.loan_amount, 10),
            loan_amount: form.value.loan_amount ? +form.value.loan_amount.replace(/[^0-9.]+/g, '') : 0,
            purchase_price: form.value.purchase_amount ? +form.value.purchase_amount.replace(/[^0-9.]+/g, '') : 0,
            comment: form.value.comments,
            // order_close_by_date: this.datepipe.transform(form.value.projected_close_date, 'yyyy-MM-dd'),
            order_close_by_date: this.datepipe.transform(this.currentDate, 'yyyy-MM-dd'),
            // pricing: this.pricing,
            pricing:form.value.pricing ? +form.value.pricing.replace(/[^0-9.]+/g, '') : 0,
            segment:"Title",

            order_received_at: this.datepipe.transform(this.currentDate, 'yyyy-MM-dd'),
            stage_id: null,
            transaction_id: null,
            apn_number: null,
            loan_no: null,
            order_category_status: null,
            emp_ids: [this.clientId],
            nb_order_ref: '1234',
            property: {
                address: form.value.address,
                county: this.selectedCountyItems[0].County,
                state: this.selectedStateItems[0].abbreviation,
                zip: form.value.zip_code,
            },
            // stage_id:"5f952dc13f25a863f9d39970",
            // transaction_id:"5feb6793d749b714898808ac",
            // apn_number:"fe",
            // loan_no:"f w",
            // seller_names: "seller_names",
            // buyer_names:"erg",
            // name:"has",
            // underwriter_name: form.value.underwriter_name
            // close_date: form.value.projected_close_date.toString(),
            // fileDetails: this.fileDetails,
            // client_id:localStorage.getItem('emailId'),
            order_created_by: "client",
            fileLocation: this.fileArray,
            hrs: this.hours,
            mins: this.mins
        };   

        // if (isArray(form.value.borrower_names)) {
        //     data['buyer_names'] = form.value.borrower_names;
        // }
        // if (isArray(form.value.seller_names)) {
        //     data['seller_names'] = form.value.seller_names;
        // }
        console.log('final data', data);

        Swal.fire({
            imageUrl: '/assets/warning-sweet-alert.svg',
            imageWidth: 57,
            imageHeight: 57,
            title: 'Are you sure?',
            text: 'You want to create this order?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00A8E8',
            cancelButtonColor: '#F4F5F8',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                this.saveOrderData(data);
            }
        });
    }

    onReset(form: NgForm) {
        this.countyRequiredError = false;
        this.stateRequiredError = false;
        this.purposeRequiredError = false;
        this.countyOpen = false;
        this.stateOpen = false;
        this.purposeOpen = false;
        this.projectedCloseDate = false;
        this.borrowerNames = false;
        this.sellerNames = false;
        this.enablePurpose = false
        this.pricing_input = false
        form.resetForm();
    }

    saveOrderData(data) {
        // console.log("final form data",data);
        this.orderService.saveClientOrderDetails(data)
        // this.orderService.saveTitleOrderDetails(data)
            .subscribe(Obj => {
                toastr.success('Order Created Successfully');
                this.router.navigate(['./myOrder']);
            },
                error => {
                    toastr.error('Something went wrong');
                    // console.log('Order Creation failed', error);
                }
            );
    }

    purposeOpenFunction(status){
        this.purposeOpen = status;
        this.enablePurpose = false;
    }

    uploadFile(event) {
        this.showLoader = true;
        // var validExts = new Array(".xlsx", ".xls", ".csv",".pdf");
        // var fileExt = event;
        // fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
        // if (validExts.indexOf(fileExt) < 0) {
        // 	toastr.error("Invalid file type, only Excel or CSV data file allowed!");
        // 	return false;
        // }
        // else {
        const newFile = event.files[0];
        const fileReader = new FileReader();

        fileReader.readAsArrayBuffer(newFile);
        // console.log('New File : ',newFile);
        this.orderService.fileUpload(newFile).subscribe(obj => {
            // console.log('uploaded', obj['key']);
            // this.fileArray = obj['key']

            var filedata = {
                file_name: obj['key'],
                file_sender: "client",
                file_sender_id: this.clientId,
                created_at: this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss'),
                updated: this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss')
            }
            console.log('File Data : ', filedata)
            this.fileArray.push(filedata)

            this.fileDetails.push(obj);
            this.showLoader = false;
        },
        error => {
            toastr.error('Something went wrong');
        this.showLoader = false;

            // console.log('File Upload failed', error);
            // this.loginService.infoFlag = false;
        });
    }

    removeFile(index) {
        this.fileDetails.splice(index, 1);
    }

    handlerDatepicker() {
        // this.projectedCloseDateError = false;
        const val = $('#my-date-picker').val();
        if (val) {
            this.projectedCloseDate = true;
        } else {
            this.projectedCloseDate = false;
        }
    }

    convertToCurrency(amt, model) {
        if (amt.substring(0, 1) !== '$') {
            amt = this.convertToNumber(amt);
            this[`${model}_value`] = amt;
            this[model] = this.currency.transform(parseInt(amt, 10));
        } else {
            const newVal = this.convertToNumber(amt.substring(1, amt.length));
            this[`${model}_value`] = newVal;
            this[model] = this.currency.transform(parseInt(newVal, 10));
        }
    }

    onClickedOutside(event){
        this.hideOrderProducts = true
    }

    productTypeSelection(productType){
        this.productname = productType[0].product_name
        // console.log('product', productType[0].product_name)
        // this.showOrderDetailsForm = false;
        // console.log('State : ', this.selectedStateItems)
        // console.log('County : ', this.selectedCountyItems.length)
        // console.log('Product Type : ',productType[0].product_name)
        if(this.selectedCountyItems.length === 0){
            // console.log('d1',this.selectedCountyItems);
            var state = '';
            var county = '';
        }else{
            // console.log('d2',this.selectedCountyItems[0].State);
            state = this.selectedCountyItems[0].State,
            county = this.selectedCountyItems[0].County
        }
        var data = {
            client_id: this.clientId,
            product_name: this.selectedProductTypes[0].product_name,
            county: county,
            state: state
        }
        this.ngxUILoader.start();
        this.orderService.getOrderPrice(data).subscribe((data:any) => {
            if(data == "Please select the correct product name, state, county"){
                // console.log('Data1 : ',data);
                // toastr.error(data);
                this.pricing = '';
                this.ngxUILoader.stop();
            }else{
                // console.log('Data2 : ',data.pricingArray[0].order_price);
                let price = (data.pricingArray[0].order_price).toString();
                let OrderPrice = this.Currencyconvert(price, 'pricing');
                this.pricing = OrderPrice;
                console.log("priz",this.pricing)
                this.ngxUILoader.stop();
            }

        })

        var datas = {
            product_id: this.selectedProductTypes[0]._id
        }
        this.orderService.getDueByDate(datas).subscribe((res: any) => {
            this.hours = res.businessDays[0].hrs
            this.mins = res.businessDays[0].mins
            var date = res.businessDays[1].due_by_date.split('T')[0]
            var time = res.businessDays[1].due_by_date.split('T')[1].split('.')[0]
            this.duebyDate = date + ' ' + time
        })
    }

    deproductTypeSelection(productType){
        // this.selectedProductType = undefined;
        // this.showOrderDetailsForm = false;
    }
    lenderSelection(data) {}

    getProductdata(Id){
        this.orderService.getProductByClientId(Id).subscribe((data: any) => {
            // console.log('product type : ', data);
            this.productType = data.productList.data
        })
    }

    getLender(clientid) {
        // console.log({clientId: clientid})
        this.orderService.getClientByLender({ clientId: clientid})
        .subscribe( (data:any) => {
          this.lenderNames = data.lenderList.data;
        })
    }

    stateSelection(state) {
        // this.selectedStateItems = undefined
        // console.log(state[0].abbreviation)
        this.stateRequiredError = false
        this.stateOpen = true
        const x = this.countries.filter( X => state[0].abbreviation.includes(X.State))
        this.counties = x;
         console.log(x)
    }
    destateSelection(state) {
        this.selectedCountyItems = []
        this.selectedStateItems = undefined;
        this.selectedCountyItems = undefined
        this.counties = []
    }

    prioritySelection(priorities){}
    dePrioritySelection(priorities){}

    countrySelection(county) {
        if(this.selectedProductTypes === undefined){
            toastr.error('Please select product type');
        }else{
            var data = {
                client_id: this.clientId,
                product_name: this.selectedProductTypes[0].product_name,
                county: county[0].County,
                state: county[0].State
            }
            // console.log('Final Data : ', data);
            this.ngxUILoader.start();
            this.orderService.getOrderPrice(data).subscribe((data:any) => {
                if(data == "Please select the correct product name, state, county"){
                    // console.log('Data1 : ',data);
                    // toastr.error(data);
                    this.pricing = '';
                    this.ngxUILoader.stop();
                }else{
                    // console.log('Data2 : ',data);
                    let price = (data.pricingArray[0].order_price).toString();
                    let OrderPrice = this.Currencyconvert(price, 'pricing');
                   this.pricing = OrderPrice;
                    this.ngxUILoader.stop();
                }

            })
        }
    }

    decountrySelection(country) {
        this.selectedCountyItems = undefined;
    }


    Currencyconvert(amt, model) {
        if (amt.substring(0, 1) !== '$') {
            amt = this.convertToNumber(amt);
            this[`${model}_value`] = amt;
            return this.currency.transform(parseInt(amt, 10));
        } else {
            const newVal = this.convertToNumber(amt.substring(1, amt.length));
            this[`${model}_value`] = newVal;
            return this.currency.transform(parseInt(newVal, 10));
        }
    }

    convertToNumber(currency) {
        if (currency.slice(-3, currency.length) === '.00') {
            currency = currency.slice(0, currency.length - 3);
        }
        return currency.split(',').join('');
    }
}
