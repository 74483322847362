import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MessagesComponent } from "../messages/messages";
import { OrderService } from "../../services/orderService";
import { ChatService } from '../../services/chatService';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.html',
  styleUrls: ['./chat.scss']
})

export class ChatComponent {

  hideChatComponent

  @ViewChild(MessagesComponent) messageComp
  orderID: any;
  client_id: any;

  constructor(private orderService: OrderService, private route: ActivatedRoute, private chatService: ChatService) {

  }

  ngOnInit(){
    this.hideChatComponent = true
    this.route.params.subscribe(obj => {
      this.orderID = obj.id
    })

    this.orderService.getOrderDetails(this.orderID).subscribe(data => {
      this.client_id = data['_id']
    })

    this.recevedMsg()
  }

  recevedMsg(){
    this.chatService.getMessages()
      .subscribe((message) => {
        console.log('All Chat data : ', message);
        this.messageComp.messages.push(message);
    });
  }       

  receiveMessage(event) {
    this.hideChatComponent = false
    this.messageComp.messages.push({ message: event.chatInput, created_at: new Date(), fileLocation: event.files, type: event.type })
  }

  // receiveMessage(event){
  //   console.log(event)
  //   this.hideChatComponent = false
  //   this.messageComp.messages.push({ message: event.chatInput,created_at : new Date(),fileLocation: event.files })
  //   console.log(this.messageComp.messages)
  // }

  showChatConsole(event) {
    this.hideChatComponent = false
  }

}