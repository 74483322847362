import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';


@Injectable({
    providedIn: 'root'
  })


  export class ReportsService {
    configUrl = new Config().apiUrl
    constructor(private http: HttpClient) { }



    getMaster(type){
      //  return this.http.get(this.configUrl + '/order/dashboard/orderStatusCount')
      return this.http.get(this.configUrl + '/manage/master/', { params: {type:JSON.stringify(type)} })

    }

    getBatchtype(){
      //console.log(data)
      return this.http.get(this.configUrl + '/neworder/fetchBatchIdBasedOnClientId')
    }


    getClientreport(data:any){
      //console.log(data)
      return this.http.post(this.configUrl + '/neworder/clientReports',data)
    }

   

    deleteFile(file){
      return this.http.delete(this.configUrl + '/file/delete?fileName=' + file)
  }



  }