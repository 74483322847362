import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/loginService';
import { OrderService } from "../../services/orderService";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // host: {
  //   '(document:click)': 'hideNotification($event)',
  // },
})
export class HeaderComponent implements OnInit {

  showNotification
  clientEmail
  clientName
  isDashboardShow: boolean = true;
  isMyOrdersShow: boolean = true;
  iscreateOrderShow: boolean = true;

  constructor(private router: Router,private loginService:LoginService, private orderService: OrderService) { }

  ngOnInit() {
    this.getClientDetails()
  }

  toggleNotification() {
    console.log("notification")
    this.showNotification = !this.showNotification
  }
  @Output() searchEvent = new EventEmitter()
  @Output() isLogoutEvent = new EventEmitter();

  refresh() {
    console.log("seracheenr")
    this.searchEvent.emit(false)
    this.router.navigate(['/myOrder'])
  }

  dashboard() {
    console.log("seracheenr")
    this.searchEvent.emit(false)
    this.router.navigate(['/dashboard'])
  }

  onClickedOutside(event){
    this.showNotification = false;
  }

  getClientDetails(){
    this.clientEmail = localStorage.getItem('emailId')
    this.orderService.getClientDetails(this.clientEmail).subscribe((data:any) => {
      this.clientName = data.client.companyName
      localStorage.setItem('clientID', data.client._id);
      if(data.client.publicApiKey === undefined ) {
        // localStorage.setItem('apiKey', null);
      }else {
        localStorage.setItem('apiKey', data.client.publicApiKey);
      }
      data.client.secondaryLogins.forEach(each => {
        if(each.email_id === this.clientEmail) {
          this.isDashboardShow = each.dashboard;
          this.isMyOrdersShow = each.myOrders;
          this.iscreateOrderShow = each.createOrder;
          if(this.isDashboardShow) {
            this.router.navigate(['./dashboard']); 
          }else if(this.isMyOrdersShow) {
            this.router.navigate(['./myOrder']); 
          }else if(this.iscreateOrderShow) {
            this.router.navigate(['./createOrder']); 
          }else {
            this.router.navigate(['./dummy']); 

          }
        }
      });
    })
  }

  logOut(){
    localStorage.clear()
    this.router.navigate(['login'])
    this.loginService.infoFlag = false;
  }

}
