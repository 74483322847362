import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './/app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { CurrencyPipe } from "@angular/common";
import {
  HeaderComponent,
  HomeComponent,
  SingleOrderComponent,
  OrderSubHeaderComponent,
  OrderComponent,
  OrderListViewComponent,
  activityListViewComponent,
  ActivityComponent,
  OrderDetailedViewComponent,
  OrderNavbarComponent,
  ChatComponent,
  MessagesComponent,
  ChatConsoleComponent,
  FilesComponent,
  SearchComponent,
  BatchOrderComponent,
  OrderProductsComponent,
  OrderOverviewComponent,
  SubmissionsComponent,
  OrderFeedbackComponent,
  ActivityOrderViewComponent,
  NotificationsComponent,
  LoginComponent,
  FooterComponent,
  FirstTimeLoginComponent,
  ResetPasswordComponent
} from './components';
import {
  DummyPageComponent,
  MyOrderComponent,
  ClientBatchOrderComponent,
  orderDetailPageComponent,
  CreateOrderPageComponent,
  CreateBatchOrderComponent,
  OrderFeedbackPageComponent
} from './pages';
import { NgDatepickerModule } from 'ng2-datepicker';
import { OrderService } from './services/orderService';
import { HttpModule } from '@angular/http';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { TooltipModule } from "ng2-tooltip-directive";
import { searchService } from './services/searchService';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { NgxPaginationModule } from 'ngx-pagination';
import { ClickOutsideModule } from 'ng4-click-outside';
import { LoginService } from './services/loginService';
import { AuthInterceptor } from './services/AuthService';
import {  HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { FulfillmentGeneralViewComponent } from './fulfillment-general-view/fulfillment-general-view.component';
import { FulfillmentPlusTypeViewComponent } from './fulfillment-plus-type-view/fulfillment-plus-type-view.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ChartsModule } from 'ng2-charts';
import { DashboardService } from './services/dashboard.service';
import { ReportsService } from './services/reports.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SettingsComponent } from './components/settings/settings.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SingleOrderComponent,
    OrderSubHeaderComponent,
    DummyPageComponent,
    OrderComponent,
    OrderListViewComponent,
    activityListViewComponent,
    ActivityComponent,
    OrderDetailedViewComponent,
    OrderNavbarComponent,
    ChatComponent,
    MessagesComponent,
    ChatConsoleComponent,
    FilesComponent,
    SearchComponent,
    BatchOrderComponent,
    OrderProductsComponent,
    MyOrderComponent,
    ClientBatchOrderComponent,
    orderDetailPageComponent,
    CreateOrderPageComponent,
    OrderOverviewComponent,
    CreateBatchOrderComponent,
    SubmissionsComponent,
    OrderFeedbackComponent,
    OrderFeedbackPageComponent,
    ActivityOrderViewComponent,
    NotificationsComponent,
    LoginComponent,
    FooterComponent,
    FirstTimeLoginComponent,
    FulfillmentGeneralViewComponent,
    FulfillmentPlusTypeViewComponent,
    DashboardComponent,
    ReportsComponent,
    SettingsComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgDatepickerModule,
    HttpModule,
    TagInputModule,
    AngularMultiSelectModule,
    TooltipModule,
    NgxUiLoaderModule,
    NgxPaginationModule,
    ClickOutsideModule,
    HttpClientModule,
    ChartsModule,
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [
    NgDatepickerModule
  ],
  providers: [
    OrderService,
    DashboardService,
    ReportsService,
    CurrencyPipe,
    searchService,
    LoginService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
