import { Component } from "@angular/core";

@Component({
    selector:'app-search',
    templateUrl:'./search.html',
    styleUrls:['./search.scss']
})

export class SearchComponent{

}