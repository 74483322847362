import { Component } from "@angular/core";
import { Router } from '@angular/router';


@Component({
    selector: 'app-client-batch-order',
    templateUrl:'./client-batch-order.html',
    styleUrls:['./client-batch-order.scss']
})
export class ClientBatchOrderComponent{

   constructor(){
   }

   

}