import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { OrderService } from '../../services/orderService';
import { ActivatedRoute, Router } from "@angular/router";

import { ChatService } from '../../services/chatService';
import { Config } from '../../config'
@Component({
    selector:'app-messages',
    templateUrl:'./messages.html',
    styleUrls:['./messages.scss']
})

export class MessagesComponent implements OnInit{
    configUrl = new Config().apiUrl
    orderID
    messages
    hideEmptyMsg
    client_id
    clientEmail
    clientName
    orderData
    clientLogo
    myInterval

    constructor(private orderService:OrderService,private route:ActivatedRoute, private chatService: ChatService, private router: Router){}

    ngOnInit(){
        this.route.params.subscribe(obj =>{
            this.orderID = obj.id
            this.getMessages(this.orderID)
        })

        this.chatService.joinRoom({ order_id: this.orderID })
       
        this.orderService.getOrderDetails(this.orderID).subscribe(data => {
            this.client_id = data['client_id']
            const clientId = data['client_id']
            this.getAllmessageData(clientId)            
            const currentUrl = '/orderDetail/'+this.orderID;
            if(this.router.url === currentUrl){
                this.myInterval = setInterval(() => {
                    this.getAllmessageData(clientId);
                }, 2000);
            }
        })
        

        this.chatService.getMessages().subscribe((message) => {
        // console.log('All Chat data : ', message);
        this.messages = message.messages;
        // console.log('All Message : ', this.messages);
        if (this.messages.length) {
            this.startChat()
        }
        });
         
    }

    @Output() showConsoleEvent = new EventEmitter<string>();

    ngOnDestroy() {
        if (this.myInterval) {
          clearInterval(this.myInterval);
        }
    }
    
    getAllmessageData(client_id){
        const data = {
            order_id: this.orderID,
            client_id: client_id
        }
        this.orderService.getAllOldMessages(data).subscribe((res: any) => {
            // console.log('MSG : ', res)
            // console.log('MSG : ', res.length)
            if(res.length != 0){
                this.messages = res[0].messages;
                this.clientName = res[0].client_name
                if (this.messages.length) {
                    this.startChat()
                }
            }
            
        })
    }

    getMessages(orderID){
        this.orderService.getOrderMessages(orderID).subscribe(obj => {
            this.messages = obj;
            if (this.messages.length) {
                this.startChat()
            }
        })
    }    

    ngOnChanges(changes){
        console.log(changes)
    }

    startChat() {
        this.hideEmptyMsg = true
        this.showConsoleEvent.emit('show chat console')
    }

    downloadFile(fileName){
        window.location.href = 'https://api.closeville.com/api/v1/file/read?fileName=' + fileName;
    }

}