import { Component } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private loaderService: NgxUiLoaderService) {}

  ngOnInit() {
    this.someAsyncOperation();
  }

  someAsyncOperation() {
    this.loaderService.start(); // Start loader
    // Simulating an async operation
    setTimeout(() => {
      this.loaderService.stop(); // Stop loader after 3 seconds
    }, 3000);
  }
}
