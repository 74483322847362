import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {DummyPageComponent} from "./pages";
import { MyOrderComponent, orderDetailPageComponent, CreateOrderPageComponent, CreateBatchOrderComponent, OrderFeedbackPageComponent, ClientBatchOrderComponent } from "./pages";
import { NgDatepickerModule } from 'ng2-datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from "ng2-tooltip-directive";
import { LoginComponent ,FirstTimeLoginComponent, HomeComponent } from './components';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SettingsComponent } from './components/settings/settings.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'reports',
    component: ReportsComponent
  },
  {
    path: 'settings',
    component: SettingsComponent
  },
  {
    path: 'dummy',
    component: DummyPageComponent
  },
  { path:'',component:MyOrderComponent},
  {
    path: 'myOrder',
    component: MyOrderComponent
  },
  {
    path: 'clientBatchOrder',
    component:ClientBatchOrderComponent
  },
  {
    path: 'orderDetail/:id',
    component: orderDetailPageComponent
  },
  {
    path: 'createOrder',
    component: CreateOrderPageComponent
  },
  {
    path: 'createBatchOrder',
    component: CreateBatchOrderComponent
  },
  {
    path: 'orderFeedback/:id',
    component: OrderFeedbackPageComponent
  },
  {
    path:'login',
    component: LoginComponent
  },
  {
    path:'firstTimeLogin',
    component: FirstTimeLoginComponent
  },
  {
    path:'privacyPolicy',
    component: PrivacyPolicyComponent
  },
  {
  path:'termsConditions',
  component: TermsAndConditionsComponent
  }
]
@NgModule({
  imports: [
    CommonModule, RouterModule.forRoot(routes, { enableTracing: false }),
    NgDatepickerModule,
    BrowserAnimationsModule,
    TooltipModule
  ],
  exports: [RouterModule,NgDatepickerModule],
  declarations: []
})
export class AppRoutingModule { }
