import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../services/orderService';
import OrderType from "../../services/orderTypeService";
import { NgxUiLoaderService } from "ngx-ui-loader";
import Swal from 'sweetalert2';
declare var jquery: any;
declare var $: any;

interface Names {
    value: string;
}

@Component({
    selector: 'app-order-overview',
    templateUrl: './order-overview.html',
    styleUrls: ['./order-overview.scss']
})
export class OrderOverviewComponent implements OnInit {
    orderOverview;
    orderId
    messages: any = [];
    defaultOrderTypes = OrderType.types()
    defaultOrderDescriptions = OrderType.productDescription()
    clientId: any;

    constructor(private activatedRoute: ActivatedRoute, private orderService: OrderService,private router:Router,private ngxUiLoader:NgxUiLoaderService) {
        this.orderOverview = {};
    }


    ngOnInit() {
        // this.ngxUiLoader.start();
        this.activatedRoute.params.subscribe(p => {
            this.orderId = p.id
            this.getOrderOverviewData(p.id);
        });
        this.getMessages(this.orderId)
        
    }
    viewall(){
        $('.navMsg').stop().addClass('active');
        $('.navOvr').stop().removeClass('active');
    }
    
    getOrderOverviewData(orderID) {
        this.ngxUiLoader.start();
        this.orderService.getOrderDetails(orderID).subscribe(data => {
            this.orderOverview = data;
            console.log('All Data : ', data)
            console.log('Order Details : ',this.orderOverview);
            this.clientId = data['client_unique_id']
            console.log('Client Id : ',this.clientId)
            // setTimeout(() => {
            //     this.ngxUiLoader.stop(); // stop foreground spinner of the master loader with 'default' taskId
            //   }, 1000)
            this.ngxUiLoader.stop();
            this.getLastfiveMessage()
        });
      
    }

    updateStatusToCompleted() {
        let data = {}
        data['status'] = 'Completed'
        data['id'] = this.orderId
        data['cleint_created_order_id'] = this.orderId
        Swal.fire({
            imageUrl: '/assets/warning-sweet-alert.svg',
            imageWidth: 57,
            imageHeight: 57,
            title: 'Are you sure?',
            text: 'You want to accept this order?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00A8E8',
            cancelButtonColor: '#F4F5F8',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                this.orderService.updateStatus(data).subscribe(res => {
                    // console.log("order completed", res)
                    this.router.navigateByUrl('/myOrder?tab=Completed')
                }) 
            }
        });
    }

    flattenNames(names: Array<Names>) {
        if (names) {
            const arr = names.map(o => o.value);
            return arr ? arr.join(', ') : '';
        }
    }
    updateStatus() {
        console.log("checkeddd")
        var data = {}
        data['status'] = "Submitted"
        data['id'] = this.orderId
        this.orderService.updateStatus(data).subscribe(data => {
            this.router.navigateByUrl('/myOrder?tab=Submitted')
            })
      

    }

    getMessages(orderID){
        this.orderService.getOrderMessages(orderID).subscribe(obj => {
            this.messages = obj;
            console.log('Message Data : ', obj)
        })
    }

    getLastfiveMessage(){
        const data ={
            order_id: this.orderId,
            client_id: this.clientId
        }
        // console.log('Data : ', data)
        this.orderService.getLastMessag(data).subscribe(obj => {
            // console.log('All MSG : ', obj['messages'].sort().reverse())
            if(obj != null){
                this.messages = obj['messages'].sort().reverse()
            }
            
            // console.log('Get 5 msg : ', this.messages)
            // console.log('Get 5 msg type : ', this.messages[0].type)
        })
    }

}
