export class Config {
    // Production Api
   public apiUrl="https://api.closeville.com/api/v1"


    // Development Api
    // public apiUrl="http://18.189.224.207:8085/api/v1"

    // WebSocket API
 // url = "http://18.189.224.207:3000"
   url = "https://api.closeville.com" 


}