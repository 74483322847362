import { Component, Input, SimpleChange, SimpleChanges } from "@angular/core"
import { OrderService } from "../../services/orderService";
import { searchService } from "../../services/searchService";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MyOrderComponent } from "../../pages/my-order/my-order"
// import OrderType from "src/app/services/orderTypeService";

@Component({
    selector: 'order-list-view',
    templateUrl: './orderListView.html',
    styleUrls: ['./orderListView.scss']
})

export class OrderListViewComponent {

    orders: any
    status: any
    statusa: any
    clientdownload_key: any;
    batchclient_details: any = [];
    clientBatchorder_data: any = [];
    clientkey_value: any;
    apiData
    modifiedData: any
    new_status: any;
    search: boolean
    tempvar_clientbatch = "clientBatch"
    temporaryStatus = "showALL"
    temporaryTypes = "showALL"
    temporaryCounty = "showALL"
    temporaryStates = "showALL"
    sortValue: boolean
    // statusSearch
    // typeSearch
    client_id
    isFirstLoad = true;
    _searchorderData: any;
    private _filterTableData: any;
    @Input('searchorderData') set searchorderData(value: any) {
        this._searchorderData = value;
        if(!this.isFirstLoad) {
            this.orders = [];
            this.orders = this._searchorderData
            
            // this.orders = this._searchorderData;
            console.log('this.orders', this.orders)
        }
    }

    @Input('filterTableData') set filterTableData(value: any) {
        this._filterTableData = value;
        if(!this.isFirstLoad) {
            this.orders = [];
            this.orders = this._filterTableData.result;
            
            // this.orders = this._searchorderData;
            console.log('this.orders', this.orders)
        }
    }

    @Input()
    orderStatusFilter

    @Input()
    searchEvent

    @Input()
    orderType

    @Input()
    county

    @Input()
    state
    query: {};
    p: number;
    statuss: any;
    statusCount: Object;

    constructor(private orderService: OrderService, private searchService: searchService,private ngxUiLoader:NgxUiLoaderService,
        private myOrderComponent: MyOrderComponent) {

    }
    @Input()
    viewState

    ngOnInit() {
        this.p = 1;
        this.ngxUiLoader.start();
       // this.status = 'Open'
       // this.status = this.new_status
       // this.statusa = 'ClientBatch'
        //this.modifiedData = []
        this.search = false
        this.viewState = 'list'
        this.sortValue = false
        this.orders = []
        this.orderService.joinRoom().subscribe(data => {
            // console.log('Data : ',data['unique_id'])
            this.client_id = data['unique_id']
        })
        // var data ={
        //     'pageNO': this.p,
        //     'status': "Open"
        // }

        

        // this.orderService.getOrderbypage(data).subscribe((data: any) => {
        //     // console.log('All orders ',data)
        //     this.apiData = data
        //     this.orders = data.filter(o => o.status === 'Open')
        //     this.ngxUiLoader.stop();
        // })
       
        this.getAllordersdata(this.status);
        this.getStatusdata();
         //this.getAllBatchclientdata();
        // this.orderService.getClientOrders(this.client_id).subscribe((data:Array<any>) => {
        //     console.log(data, "data")
        //     this.apiData = data
        //     // this.orders = data.filter(o => o.status === 'Open')
        //     this.ngxUiLoader.stop();
        // })

    }

    ngOnChanges(change: SimpleChanges) {
        console.log(change);
        
        if(change.orderStatusFilter) {
            var cStatus = this.myOrderComponent.getStatusdata();
            console.log("orderStatusFilter", change)
            this.getAllordersdata(cStatus);
        }
        // if (change.searchEvent) {
        //     this.search = change.searchEvent.currentValue
        //     this.orders = this.search ? this.apiData.filter(o => o.status == this.status) : this.apiData
            
        // }
        // else if (change.orderStatusFilter) {
        //     if (this.orders) {
        //         if (!change.orderStatusFilter.currentValue[0].itemName && change.orderStatusFilter.currentValue != "showALL") {
        //             this.status = change.orderStatusFilter.currentValue
        //         }

        //         if (change.orderStatusFilter.currentValue == this.status) { this.orders = this.apiData.filter(o => o.status === this.status) }

        //         else {
        //             this.temporaryStatus = change.orderStatusFilter.currentValue[0].itemName ? change.orderStatusFilter.currentValue[0].itemName : change.orderStatusFilter.currentValue
        //         }
        //     }
        //     else {
        //         this.orderService.getClientOrders(this.client_id).subscribe(data => {
        //             this.apiData = data
        //             this.orders = this.apiData.filter(o => o.status === change.orderStatusFilter.currentValue)
        //         })

        //     }
        // }

    //     else if (change.orderType) {
    //         this.temporaryTypes = change.orderType.currentValue[0].itemName ? change.orderType.currentValue[0].itemName : change.orderType.currentValue
    //         this.temporaryTypes = this.temporaryTypes.replace(/\s/g, "_")
    //     }

    //     else if (change.county) {
    //         console.log("checked",change)
    //         this.temporaryCounty = change.county.currentValue[0].County ? change.county.currentValue[0].County : change.county.currentValue
    //     }

    //     else if (change.state) {
    //         this.temporaryStates = change.state.currentValue[0].name ? change.state.currentValue[0].name : change.state.currentValue
    //     }
    //    if(this.search)
    //     this.orders = this.searchService.searchFilter(this.apiData, this.temporaryStatus, this.temporaryCounty, this.temporaryStates, this.temporaryTypes);

    }

    sort(value) {
        this.sortValue = !this.sortValue
        this.orders = this.sortByKey(value)
    }

    sortByKey(value) {
        return this.orders.sort((a, b) => {
            var x = (value == 'pricing') ? parseInt(a[value]) : (value == 'property') ? a['property'].address : a[value]
            var y = (value == 'pricing') ? parseInt(b[value]) : (value == 'property') ? b['property'].address : b[value]
            return this.sortValue ? ((x < y) ? -1 : ((x > y) ? 1 : 0)) : ((x > y) ? -1 : ((x < y) ? 1 : 0));
        });
    }

    onCustomEvent(response: string) {
        console.log(response)
    }

    receiveTabStatus(event) {
        console.log(event, "tabEvent")

    }

    getAllordersdata(status){
        // console.log(status);
        this.isFirstLoad = false;
        if (status == 'Submitted'){
            var CurrentStatus = 'OperationSubmitted';
        }else{
            CurrentStatus = status
        }
        if(status == 'ClientBatch'){
            var CurrentStatus = this.tempvar_clientbatch;
        }else{
            CurrentStatus = status
        }
        var data ={
            'pageNO': this.p,
            'status': CurrentStatus
        }
       // this.new_status = data.status
         this.status = data.status

        console.log("asasas..", data)
        console.log("new status..",this.new_status)
        this.orderService.getOrderbypage(data).subscribe((data: any) => {
           console.log('All orders ',data)
            this.apiData = data
            console.log("apidata",this.apiData)

            if(CurrentStatus == 'All'){
                this.orders = data
                console.log(this.orders)
            } 
            else{
                console.log("dfdff...",data)
                this.orders = data.filter(o => o.status === CurrentStatus)
                console.log('oderrr..',this.orders)
                if(this.status == 'clientBatch'){
                    console.log("haii")
                    this.clientBatchorder_data = this.orders;
                    console.log("apidata",this.clientBatchorder_data)
                    for(let item of this.orders){
                        console.log("itemmm",item.fileLocation)
                        for(let x of item.fileLocation){
                            console.log("key...",x.key)
                            this.clientdownload_key = x.key
                            console.log('11',this.clientdownload_key)
                        }
                    }
                }
            }


           
            this.ngxUiLoader.stop();
            this.getStatusdata();
        })
    }

    downloadFile(data?:any){
        var fileName = ''
        console.log("rowdata",data)
        console.log("apidata",this.clientBatchorder_data)
        for(let x of this.clientBatchorder_data ){
            console.log(x)
            if(x._id == data._id){
                for(let y of data.fileLocation){
                    fileName = y.Key
                    window.location.href = 'https://api.closeville.com/api/v1/file/read?fileName=' +fileName ;
                }
                    
            }
        }
        
        
    }

    getStatusdata(){
        this.orderService.getStatusCount().subscribe(data => {
            this.statusCount = data
            // console.log('count : ', data);
          })
    }
//     getAllBatchclientdata(){
//         var data ={
//             'page_no': this.p,
//         }
//         console.log("pagenu...",data)
//         this.orderService.getBatchdata(data).subscribe((data: any) => {
//             console.log('All clientdata ',data)
//             this.batchclient_details = data
//             console.log(this.batchclient_details)
//             for(let x of this.batchclient_details ){
//                // console.log('to get keyy',this.batchclient_details)
//                 for(let y of x.fileLocation){  
//                     this.clientkey_value = y.Key
//                 }
//             }
            
//     })
//     }

}