import { Component, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { LoginService } from "../../services/loginService";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AuthStorageService } from "../../services/auth-storage.service";
declare var toastr:any


@Component({
    selector: 'app-login',
    templateUrl: './login.html',
    styleUrls: ['./login.scss']
})

export class LoginComponent {
    loginState
    modalState
    login_username
    login_password
    secret
    loginUpdate: boolean;
    forgotPwdEmail

    public href: string = "";

    url:string = ""


    constructor(private loginService:LoginService, private authStorageService: AuthStorageService , private router:Router,private route:ActivatedRoute, private ngxUiLoader: NgxUiLoaderService){}

    ngOnInit() {
        this.href = this.router.url;
        console.log(this.router.url);
        this.url = this.router.url
        this.ngxUiLoader.stop()
        this.loginState = 'login'
        this.modalState = 'resetPwdModal'
        this.route.queryParams.subscribe(p => {
            console.log(p, this.route,'pppppppppppppppp')
            this.secret = p.code
        })
        if (localStorage.getItem('access_token')) {
            this.loginService.verifyToken(localStorage.getItem('access_token')).subscribe(data => {
                if (!data.res) {
                    this.router.navigate(['/otpvalidation'])
                }
            })
        }
    }

    // changeLoginState(state) {
    //     this.loginState = state
    // }

    changeLoginState(state: string) {
        this.loginState = state;
        if (state == 'forgotPassword') {
            this.forgotPwdEmail = localStorage.getItem('email'); // Set the forgot password email
        }
    }
    

    changeModalState(state) {
        this.modalState = state
    }

    onSubmit(form: NgForm) {
        console.log(form.value)
        this.login_username = form.value.login_username
        this.login_password = form.value.login_password
        this.sendTempPassword()
            const data = {
                email : form.value.login_username,
                password : form.value.login_password,
                type: "client"
            }
            this.authStorageService.setEmail(data.email);
            this.authStorageService.setPassword(data.password);
            this.login(data)
            localStorage.setItem("email",this.login_username)
    }

    login(data) {
        if (this.secret && this.login_username && this.login_password) {
            console.log(this.secret,'secrettttttttttttttttttttttttttt')

            let short_str ='login?code=';

            if (this.url.includes(short_str)) 
                {  
                    const data = {
                        email : this.login_username,
                        password : this.login_password,
                secret:this.secret
                    }

                    this.loginService.activateClient(data).subscribe(obj=>{
                        this.router.navigate(['login/resetPassword'], { queryParams: { code: this.secret } })
                        console.log(obj,"Activation successfull")
                        this.modalState = 'resetPwdSuccessModal'
                    },error => {
                        toastr.error("Wrong Input")
                        console.log("Activation Failed",error)
                    })
                     
                }
                 else
                  {
                    this.router.navigate(['/otpvalidation'], { queryParams: { code: this.secret } });
                }

            // this.router.navigate(['/otpvalidation'], { queryParams: { code: this.secret } });
        }
        else {
            this.ngxUiLoader.start()
            this.loginService.loginToApp(data).subscribe(obj => {
                if (obj.client === 'Invalid username or password') {
                    toastr.error("Invalid username or password")
                    this.ngxUiLoader.stop()
                }
                else if (!obj.client.access && obj.client.msg === 'Your Password has expired.'){
                    this.setResetPassword()
                    toastr.error("Your Password has expired")
                    this.ngxUiLoader.stop()
                }
                else if (!obj.client.access && obj.client.msg === 'Invalid username or password') {
                    toastr.error("Invalid username or password")
                    this.ngxUiLoader.stop()
                }
                else if (!obj.client.access && obj.client.msg  ==="Your account has been blocked. Please contact the administrator for further assistance.") {
                    toastr.error("Your account has been blocked. Please contact the administrator for further assistance.")
                    this.ngxUiLoader.stop()
                }

                else if (obj.client.access) {
                    this.router.navigate(['/otpvalidation'])
                    // localStorage.setItem('emailId', this.login_username)
                    window.localStorage.setItem('access_token', obj.client.access_token)
                    // this.ngxuiloader.stop()
                }
                else {
                    toastr.error(obj.client.msg)
                    this.ngxUiLoader.stop()
                }

            }, error => {
                toastr.error("user name and password invalid")
                // toastr.error("Something went wrong")
                console.log("Login Failed", error)
                this.ngxUiLoader.stop()
            })
        }
    }

    setEmailID(){
        this.loginState = 'forgotPassword'
        this.forgotPwdEmail = this.login_username
    }

    setResetPassword(){
        this.loginState = 'resetPassword'
        this.forgotPwdEmail = this.login_username
    }

    sendTempPassword(){
        this.loginService.setValue(this.login_password)
    }

    forgotPasswordSendMail(){
        if(this.forgotPwdEmail){
            this.loginService.forgotPassword(this.forgotPwdEmail).subscribe(obj => {
                this.loginState ='emailSentMsg'
            },err=>{
                toastr.error(JSON.parse(err._body))
            })
        }
        else{
            toastr.error("Please enter the registered Email ID")
        }
    }

}