import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../services/orderService';
import Swal from "sweetalert2"
import { Config } from '../../config';
declare var toastr: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  apiKey: string = '';
  clientID:any = '';
  companyName:any = '';


  tooltipMsg: string = 'Copy API Token';
  tooltipclientMsg: string = 'Copy Client ID';

  configUrl = new Config().apiUrl;
  constructor(private orderService: OrderService) { }

  // ngOnInit() {
  //   if(localStorage.getItem('apiKey')) {
  //     this.apiKey = localStorage.getItem('apiKey');
  //   }else {
  //     this.apiKey = '';
  //   }
  // } 

  ngOnInit() {
    const obj = {
      _id: this.clientID
  }
  this.orderService.fetchPublicApiKey(obj).subscribe((data: any) => {
      console.log(data)
      console.log("publicApiKey publicApiKey",data.publicApiKey)
      this.apiKey = data.publicApiKey
      this.clientID = data.client_id
      this.companyName = data.companyName
  })


}

  // generateApi() {
  //   if (this.apiKey == '') {
  //     this.orderService.getGenerateApi().subscribe((res: any) => {
  //       console.log('res', res)
  //       this.apiKey = res.publicApiKey;
  //       localStorage.setItem('apiKey',this.apiKey);
  //       toastr.success("API key generated successfully")

  //     })
  //   } else if (this.apiKey !== '') {
  //     Swal.fire({
  //       imageUrl: '/assets/warning-sweet-alert.svg',
  //       imageWidth: 57,
  //       imageHeight: 57,
  //       title: 'Are you sure?',
  //       text: 'You want to regenerate API key?',
  //       type: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#00A8E8',
  //       cancelButtonColor: '#F4F5F8',
  //       confirmButtonText: 'Yes'
  //     }).then((result) => {
  //       if (result.value) {
  //         this.orderService.getGenerateApi().subscribe((res: any) => {
  //           console.log('res', res)
  //           this.apiKey = res.publicApiKey;
  //           localStorage.setItem('apiKey',this.apiKey);
  //           this.tooltipMsg = "Copy API key";
  //           toastr.success("API key regenerated successfully")

  //         })
  //       }
  //     });
  //   }
  // }


  generateApi(){

    const obj = {
      _id: this.clientID
  }
   if (this.apiKey == '') {
      this.orderService.getGenerateApi().subscribe((res: any) => {
        console.log('res', res)
          this.clientID = res.client_id
          this.apiKey = res.publicApiKey;
        toastr.success("API  generated successfully")
      })
    } else if (this.apiKey !== '') {
      Swal.fire({
        imageUrl: '/assets/warning-sweet-alert.svg',
        imageWidth: 57,
        imageHeight: 57,
        title: 'Are you sure?',
        text: 'You want to regenerate API Token?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00A8E8',
        cancelButtonColor: '#F4F5F8',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.value) {
          this.orderService.getGenerateApi().subscribe((res: any) => {
            console.log('res', res)
            this.apiKey = res.publicApiKey;
            this.clientID = res.client_id
            localStorage.setItem('apiKey',this.apiKey);
            this.tooltipMsg = "Copy API Token";
            toastr.success("API Token regenerated successfully")

          })
        }
      });
    }

  }

  copyApiKey(inputData: any) {
    console.log('datadata', inputData)
   var el = document.createElement('textarea');
   // Set value (string to be copied)
   el.value = inputData;
   // Set non-editable to avoid focus and move outside of view
   el.setAttribute('readonly', '');
  //  el.style = {position: 'absolute', left: '-9999px'};
   document.body.appendChild(el);
   // Select text inside element
   el.select();
   // Copy text to clipboard
   document.execCommand('copy');
   // Remove temporary element
   document.body.removeChild(el);
   this.tooltipMsg = "Copy API Token ";
   toastr.success("Copied API Token !")

  }

  copyClientId(inputData: any) {
    console.log('datadata', inputData)
   var el = document.createElement('textarea');
   // Set value (string to be copied)
   el.value = inputData;
   // Set non-editable to avoid focus and move outside of view
   el.setAttribute('readonly', '');
  //  el.style = {position: 'absolute', left: '-9999px'};
   document.body.appendChild(el);
   // Select text inside element
   el.select();
   // Copy text to clipboard
   document.execCommand('copy');
   // Remove temporary element
   document.body.removeChild(el);
   this.tooltipclientMsg = "Copy Client ID ";
   toastr.success("Copied Client ID !")

  }

  fileDownloads(){
    const fileName = "Closeville API Documentation"
    window.location.href = this.configUrl + '/file/read?fileName=' + fileName +".pdf";
  }

}
