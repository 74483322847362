import { Component, OnInit } from "@angular/core";
import { Output, EventEmitter } from '@angular/core';
import { OrderService } from "../../services/orderService";
import { ActivatedRoute,Router } from "@angular/router";
import Swal from "sweetalert2";
import OrderType from "../../services/orderTypeService";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Config } from '../../config';

@Component({
    selector:'app-order-detailed-view',
    templateUrl:'./order-detailed-view.html',
    styleUrls:['./order-detailed-view.scss']
})

export class OrderDetailedViewComponent implements OnInit{

    orderData
    defaultOrderTypes = OrderType.types()
    orderStatus: any;
    isDisabled = true;
    configUrl = new Config().apiUrl
    product_name: any;
    orderRefNo
    constructor(private orderService:OrderService,private route:ActivatedRoute,private router:Router,private ngxUiLoader:NgxUiLoaderService){}

    ngOnInit(){
        this.route.params.subscribe(p=>{
            this.getOrderDetails(p.id)
        })
    }


    @Output() clientstatusEvent = new EventEmitter<any>();
    getOrderDetails(orderID){
        console.log(orderID)
        // this.ngxUiLoader.start();
        this.orderService.getOrderDetails(orderID).subscribe(data =>{
            console.log("orderData", data)
            this.orderStatus = data['status'];
            this.product_name = data['product_name']
            this.orderData = data
            //console.log(this.orderData.status)
            this.clientstatusEvent.emit(this.orderData)
        //     localStorage.setItem('statustdetails', JSON.stringify(this.orderData))
        //    console.log('New status-details -------------- 11111 : ', this.orderData)
            this.orderRefNo = data['nb_order_ref'] ? data['nb_order_ref'] : data['op_order_ref']

            // console.log('Order Status : ', data['status'])
        })
        // this.ngxUiLoader.stop();
        // this.downloadReport(orderID);
    }

    cancelOrder() {
        console.log("checkedddd")
        let data = {}
        data['status'] = "Cancelled"
        data['id'] = this.orderData._id
        data['cleint_created_order_id'] = this.orderData._id
        data['client_order_no'] = this.orderData.client_order_no
        data['client_name'] = this.orderData.client_name

        Swal.fire({
            imageUrl: '/assets/warning-sweet-alert.svg',
            imageWidth: 57,
            imageHeight: 57,
            title: 'Are you sure?',
            text: "You want to cancel this order?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00A8E8',
            cancelButtonColor: '#F4F5F8',
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.value) {
                this.orderService.updateStatus(data).subscribe(data => {
                    this.router.navigateByUrl('/myOrder?tab=Cancelled')
                })
            }
        })
    }

    // downloadReport(){
    //     console.log("order Resf",this.orderRefNo);
        
    //     window.open(this.configUrl + '/file/ejs/' + this.orderRefNo, 'popup', 'width=800,height=800')
    // }

      
    downloadReport(){
        this.orderService.saveFulfillmentPlusTypeDatafilr(this.orderRefNo).subscribe((dta:any) =>{
            var myWindow = window.open("", "popup", 'width=800,height=800');
            myWindow.document.write(dta);
          })
    }


}