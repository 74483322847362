import { Component, OnInit } from "@angular/core";
import { OrderService } from '../../services/orderService';
import { ActivatedRoute } from "@angular/router";
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare const $
@Component({
    selector:'app-order-navbar',
    templateUrl:'./order-navbar.html',
    styleUrls:['./order-navbar.scss']
})

export class OrderNavbarComponent implements OnInit{

    orderOverview;
    orderID: any;
    clientId: any;
    OperaionOrderFiles:Array<any>     
    ClientOrderFiles:Array<any>
    client_id
    nb_order_refs: any;
    constructor(private activatedRoute: ActivatedRoute, private orderService: OrderService, private ngxUILoader: NgxUiLoaderService) {
        this.orderOverview = {};
        this.OperaionOrderFiles = []
        this.ClientOrderFiles = []
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(p => {
            this.getOrderOverviewData(p.id);
            this.orderID = p.id
        });
        console.log('Order Id 1: ',this.orderID)
    }

    getOrderOverviewData(orderID) {
        this.ngxUILoader.start()
        this.orderService.getOrderDetails(orderID).subscribe(data => {
            this.orderOverview = data;
            this.clientId = data['client_unique_id']
            this.client_id = data['client_unique_id']
            console.log('All data logged in',this.orderOverview);
            const client_id = data['client_unique_id']
            this.nb_order_refs = data['nb_order_ref'] ? data['nb_order_ref'] :  data['op_order_ref']
           // this.getAllOperationuploadedFile(client_id)
           // this.getAllClientUploadedFile(client_id)
            this.ngxUILoader.stop()
        });
        
    }

    openOrders(val){
        this.ngxUILoader.start();
        // console.log('Current Tab : ', val)
        // console.log('Client Id 21: ',this.clientId)
        // console.log('Client Id 22: ',this.client_id)
        this.getOrderOverviewData(this.orderID)
        this.getAllOperationuploadedFile(this.client_id)
        this.getAllClientUploadedFile(this.client_id)
        this.ngxUILoader.stop();
    }

    getAllOperationuploadedFile(client_id){
        const data = {
            client_id: client_id,
            order_id: this.orderID,
            file_sender: 'operation',
            nb_order_ref: this.nb_order_refs
        }
        console.log('All id : ', data)
        this.orderService.getAllFiles(data).subscribe((res:any) => {
            console.log('Operation Files : ',res)
            this.OperaionOrderFiles = res
            this.ngxUILoader.stop();
        })
    }

    getAllClientUploadedFile(client_id){
        const data = {
            client_id: client_id,
            order_id: this.orderID,
            file_sender: 'client',
            nb_order_ref: this.nb_order_refs
        }
        console.log("data...",data)    
        this.orderService.getAllFiles(data).subscribe((res:any) => {
            console.log('Client Files : ', res)
            // if(res.length != 0){
            //     this.ClientOrderFiles = res[0]['fileArray']
            // }
            this.ClientOrderFiles = res
            
            this.ngxUILoader.stop();
        })
    }

    // callJqueryMethod(){
    //     $(document).ready(function(){
    //         console.log("document is ready")
    //         $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
           
    //             console.log(e.target, "current tab ")
    //           })
    //        }
        
}