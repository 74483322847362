import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ChartConfiguration, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Color, MultiDataSet, SingleDataSet } from 'ng2-charts';
import { DashboardService } from '../../services/dashboard.service';
import CountyNames from "../../services/countyNamesService";
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  @ViewChild(DaterangepickerDirective) pickerOpen: DaterangepickerDirective;
  rangeShow
  rangeDataList = [{ id: 'this_month', name: 'This Month' }, { id: 'this_quarter', name: 'This Quarter' }, { id: 'this_year', name: 'This Year' }, { id: 'all_time', name: 'All Time' }, { id: 'custom_date', name: 'Custom Range' }]

  records
  // ---------------------
  barChartOptions: ChartOptions = {

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            // color: '#676A6C',
          }
        }
      ], yAxes: [{ display: false }]
    },
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      }
    }
  };
  // public barChartOptions: any = {
  //   scaleShowVerticalLines: false,
  //   responsive: true,
  // };
  barChartData: any[];
  mbarChartLabels: string[];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;
  public barChartColors: Array<any> = [
    {
      backgroundColor: '#0D4C92',
      borderColor: 'rgba(105,159,177,1)',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#000000',
      pointHoverBorderColor: 'rgba(105,159,177)',
    },
    {
      backgroundColor: '#285430',
      borderColor: 'rgba(77,20,96,1)',
      pointBackgroundColor: 'rgba(77,20,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#000000',
      pointHoverBorderColor: 'rgba(77,20,96,1)',
    },
    {
      backgroundColor: '#D23369',
      borderColor: 'rgba(77,20,96,1)',
      pointBackgroundColor: 'rgba(77,20,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#000000',
      pointHoverBorderColor: 'rgba(77,20,96,1)',
    },
  ];

    // events
    public chartClicked(e: any): void {
      console.log(e);
    }
  
    public chartHovered(e: any): void {
      console.log(e);
    }
  // barChartType: ChartType = 'bar';
  // // barChartLegend = true;
  // barChartPlugins = [];

  yearList: { id: string; name: string; }[];
  monthList: { id: string; name: string; }[];
  settings: { text: string; selectAllText: string; unSelectAllText: string; classes: string; enableSearchFilter: boolean; singleSelection: boolean; primaryKey: string; labelKey: string; };
  settings2: { text: string; selectAllText: string; unSelectAllText: string; classes: string; enableSearchFilter: boolean; singleSelection: boolean; primaryKey: string; labelKey: string; };
  settings5: { text: string; selectAllText: string; unSelectAllText: string; classes: string; enableSearchFilter: boolean; singleSelection: boolean; primaryKey: string; labelKey: string; };
  settings4: { text: string; selectAllText: string; unSelectAllText: string; classes: string; enableSearchFilter: boolean; singleSelection: boolean; primaryKey: string; labelKey: string; };
  chartsData: Object;
  barChartCompletionsCount: ChartDataSets[]
  barChartCompletionsLabel: Label[]
  barChartCancelledCount: ChartDataSets[]
  barChartCancelledLabel: Label[];
  lineChartcompletionTATCount: ChartDataSets[]
  lineChartcompletionTATLabels: Label[];
  lineChartcomputationTATCount: ChartDataSets[]
  lineChartcomputationTATLabel: Label[];
  doughnutChartLabel: Label[];
  doughnutChartCount: MultiDataSet[];
  OrderAndCompletetionbarChartData: ChartDataSets[]
  maxValue: number;
  
  productionSummaryChartOptions: ChartOptions = {
    responsive: true
  }
  canceledMaxValue: number;
  completionTATMaxValue: number;
  computeTATMaxValue: number;
  maxProdSummaryValue: number;
  selectedYear;
  selectedMonth;
  county_name_list = CountyNames.uniqueCountyNames();
  state_name_list = CountyNames.uniqueStateNames();
  product_name_list: { id: string; itemName: string; }[];
  settings3: { text: string; selectAllText: string; unSelectAllText: string; classes: string; enableSearchFilter: boolean; singleSelection: boolean; primaryKey: string; };
  obj = {}
  selectRange: string;
  clientData: any;
  productionSummaryData: { data: any; label: string; backgroundColor: string; }[];
  selectRangeName: string;
  public polarAreaChartLabels: Label[] 
  public polarAreaChartData: SingleDataSet;
  public polarAreaLegend = true;
  public polarAreaChartType: ChartType = 'polarArea';
  totalCost: any;
  averageOrderCost: any;
  completedOrders: any;
    // // events
    // public chartClicked({
    //   event,
    //   active,
    // }: {
    //   event: MouseEvent;
    //   active: {}[];
    // }): void {
    //   console.log(event, active);
    // }
  
    // public chartHovered({
    //   event,
    //   active,
    // }: {
    //   event: MouseEvent;
    //   active: {}[];
    // }): void {
    //   console.log(event, active);
    // }
  distribution: any;
  dateObj: { startDate: any; endDate: any; };

  public totalCostOverTimelineChartData: ChartDataSets[] = [
    { data: [], label: 'Series A' },
  ];

  public totalCostOverTimelineChartLabels: Label[] = [];

  public totalCostOverTimelineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      }
    }
  };
  public totalCostOverTimelineChartColors: Color[] = [
    {
      borderColor: 'rgb(46,139,87)',
      backgroundColor: '#749F82',
    },
  ];
  public totalCostOverTimelineChartLegend = false;
  public totalCostOverTimelineChartType: ChartType = 'line';
  public totalCostOverTimelineChartPlugins = [];

  constructor(private dashboardService: DashboardService) {}

  ngOnInit() {
    this.selectRange = 'this_year';
    this.distribution = 'product';
    this.selectedYear = [{ id: 2022, name: 2022 }];
    this.selectedMonth = [{ id: 6, name: 'Jul' }];
    this.product_name_list = [
      { 'id': '5f952e8e3f25a863f9d3997c', 'itemName': 'Current Owner Search' },
      { 'id': '5f952f323f25a863f9d39986', 'itemName': 'Current Owner Search Plus' },
      { 'id': '5f952f473f25a863f9d39988', 'itemName': 'Full Search' },
      { 'id': '5f952f563f25a863f9d3998a', 'itemName': 'Full Search Plus' },
      { 'id': '60066ee0a301d62fe9c23bfc', 'itemName': 'Two-Owner Search' },
      { 'id': '60066ef2a301d62fe9c23bfd', 'itemName': 'Two-Owner Search Plus' },
      { 'id': '60f661a1bf9612308de66c90', 'itemName': '25 Year Search' },
    ];
    this.yearList = [
      { 'id': '2020', 'name': '2020' },
      { 'id': '2021', 'name': '2021' },
      { 'id': '2022', 'name': '2022' }
    ];
    this.monthList = [
      { 'id': '0', 'name': 'Jan' },
      { 'id': '1', 'name': 'Feb' },
      { 'id': '2', 'name': 'Mar' },
      { 'id': '3', 'name': 'Apr' },
      { 'id': '4', 'name': 'May' },
      { 'id': '5', 'name': 'Jun' },
      { 'id': '6', 'name': 'Jul' },
      { 'id': '7', 'name': 'Aug' },
      { 'id': '8', 'name': 'Sep' },
      { 'id': '9', 'name': 'Oct' },
      { 'id': '10', 'name': 'Nov' },
      { 'id': '11', 'name': 'Dec' }
    ];

    this.getTitleAndCount();
    this.getYearAndMonth();
    this.obj = {
      'selectedMonth': [{ id: '7', name: 'Aug' }],
      'selectedYear': [{ id: '2022', name: 2022 }]
    }
    // let onloadReq = {
    //   'selectedMonth': [{ id: '7', name: 'Aug' }],
    //   'selectedYear': [{ id: '2022', name: 2022 }]
    // }
    // this.getDashboardCharts('year', onloadReq);
    let completionsCount = [];
    let completionsLabel = [];
    let cancelledCount = [];
    let cancelledLabel = [];
    let completionTATCount = [];
    let completionTATLabel = [];
    let computationTATCount = [];
    let computationTATLabel = [];
    let doughnutChartCount = [];
    let doughnutChartLabel = [];
    this.barChartCompletionsCount = [{ data: completionsCount }];
    this.barChartCompletionsLabel = completionsLabel;
    this.barChartCancelledCount = [{ data: cancelledCount }];
    this.barChartCancelledLabel = cancelledLabel;
    this.lineChartcompletionTATCount = [{
      data: completionTATCount,
      fill: false,
      lineTension: 0
    }];
    this.lineChartcompletionTATLabels = completionTATLabel;
    this.lineChartcomputationTATCount = [{
      data: computationTATCount,
      fill: false,
      lineTension: 0
    }];
    this.lineChartcomputationTATLabel = computationTATLabel;
    this.doughnutChartCount = doughnutChartCount;
    this.doughnutChartLabel = doughnutChartLabel;
    this.clientDashboardDetail();

  }

  getTitleAndCount() {
    this.dashboardService.orderStatusCount().subscribe(data => {
      console.log('orderStatusCount', data);
      this.records = data;
      console.log('this.records', this.records);
    })
  }

  getYearAndMonth() {
    let obj = {
      "year": ""
    }
    this.dashboardService.yearAndMonthList(obj).subscribe(data => {
      console.log('yearAndMonthList', data);
      // this.records = data;
    })
  }

  // getDashboardCharts(field, obj) {

  //   console.log('datadata', obj)

  //   let body = {
  //     "product_id": obj.selectedProductItems ? obj.selectedProductItems.length > 0 ? obj.selectedProductItems[0].id : '' : '',
  //     "state": obj.selectedStateList ? obj.selectedStateList.length > 0 ? obj.selectedStateList[0].id : '' : '',
  //     "county": obj.selectedCountyList ? obj.selectedCountyList.length > 0 ? obj.selectedCountyList[0].County : '' : '',
  //     "status": "",
  //     "month": obj.selectedMonth ? obj.selectedMonth.length > 0 ? obj.selectedMonth[0].id : '' : '',
  //     "year": obj.selectedYear ? obj.selectedYear.length > 0 ? obj.selectedYear[0].id : '' : ''
  //   }
  //   console.log('Request', body)
  //   this.dashboardService.dashboardChartsDetail(body).subscribe(data => {
  //     console.log('dashboardChartsDetail', data);
  //     this.chartsData = data;
  //     if (this.chartsData) {
  //       let completedArr = this.chartsData['completedArr'];
  //       let cancelledArr = this.chartsData['cancelledArr'];
  //       let completionTAT = this.chartsData['completionTAT'];
  //       let computationTATResponse = this.chartsData['computationTATResponse'];
  //       let doughnutChartResponse = this.chartsData['pieArr'];
  //       let OrderAndCompletetionArr = this.chartsData['monthlyOrderAndCompletetionArr'];
  //       let completionsCount = [];
  //       let completionsLabel = [];
  //       completedArr.forEach(each => {
  //         completionsCount.push(each.product_count);
  //         completionsLabel.push(each.product_name);
  //       });
  //       let allValues = completionsCount.map(ds => ds);
  //       this.maxValue = Math.max(...allValues);
  //       this.barChartCompletionsCount = [{ data: completionsCount, maxBarThickness: 60, }];
  //       this.barChartCompletionsLabel = completionsLabel;

  //       let cancelledCount = [];
  //       let cancelledLabel = [];
  //       cancelledArr.forEach(each => {
  //         cancelledCount.push(each.product_count);
  //         cancelledLabel.push(each.product_name);
  //       });
  //       allValues = cancelledCount.map(ds => ds);
  //       this.canceledMaxValue = Math.max(...allValues);
  //       this.barChartCancelledCount = [{ data: cancelledCount, maxBarThickness: 60, }];
  //       this.barChartCancelledLabel = cancelledLabel;

  //       let completionTATCount = [];
  //       let completionTATLabel = [];
  //       completionTAT.forEach(each => {
  //         completionTATCount.push(each.count);
  //         completionTATLabel.push(each.title);
  //       });
  //       allValues = completionTATCount.map(ds => ds);
  //       this.completionTATMaxValue = Math.max(...allValues);
  //       this.lineChartcompletionTATCount = [{
  //         data: completionTATCount,
  //         fill: false,
  //         lineTension: 0
  //       }];
  //       this.lineChartcompletionTATLabels = completionTATLabel;

  //       let computationTATCount = [];
  //       let computationTATLabel = [];
  //       computationTATResponse.forEach(each => {
  //         computationTATCount.push(each.count);
  //         computationTATLabel.push(each.title);
  //       });
  //       allValues = computationTATCount.map(ds => ds);
  //       this.computeTATMaxValue = Math.max(...allValues);
  //       this.lineChartcomputationTATCount = [{
  //         data: computationTATCount,
  //         fill: false,
  //         lineTension: 0
  //       }];
  //       this.lineChartcomputationTATLabel = computationTATLabel;

  //       let doughnutChartCount = [];
  //       let doughnutChartLabel = [];
  //       doughnutChartResponse.forEach(each => {
  //         doughnutChartCount.push(each.product_count);
  //         doughnutChartLabel.push(each.product_name);
  //       });
  //       this.doughnutChartCount = doughnutChartCount;
  //       this.doughnutChartLabel = doughnutChartLabel;

  //       let orderCreatedArr = [null, null, null, null, null, null, null, null, null, null, null, null]
  //       let orderCompletedArr = [null, null, null, null, null, null, null, null, null, null, null, null]

  //       let response = [{ month: "03", orderCreated: 5, orderCompleted: 14 },
  //       { month: "07", orderCreated: 13, orderCompleted: 4 }]

  //       OrderAndCompletetionArr.map((e: any) => {
  //         orderCreatedArr.splice(e.month - 1, 1, e.orderCreated)
  //         orderCompletedArr.splice(e.month - 1, 1, e.orderCompleted)
  //       })

  //       console.log("orderCreatedArr --- ", orderCreatedArr)
  //       console.log("orderCompletedArr --- ", orderCompletedArr)

  //       this.OrderAndCompletetionbarChartData = [
  //         { data: orderCreatedArr, label: 'Order', backgroundColor: '#ff6347' },
  //         { data: orderCompletedArr, label: 'Completion', backgroundColor: '#67b346' }
  //       ];

  //       this.optionConfig();
  //     }
  //   })
  // }


  searchByFilter(obj, obj2) {
    console.log(obj, obj2);
    let body = {
      "product_id": obj2.selectedProductItems ? obj2.selectedProductItems.length > 0 ? obj2.selectedProductItems[0].id : '' : '',
      "state": obj2.selectedStateList ? obj2.selectedStateList[0].id : '',
      "county": obj2.selectedCountyList ? obj2.selectedCountyList[0].County : '',
      "status": "",
      "month": obj2.selectedMonth ? obj2.selectedMonth[0].id : '',
      "year": obj2.selectedYear ? obj2.selectedYear[0].id : ''
    }
    console.log('bodybody', body)
  }

  onDistributionChange(data) {
    console.log('datadata', data);
    this.distribution = data;
    this.clientDashboardDetail();
  }

  clientDashboardDetail() {
    // console.log(data);
    console.log('this.selectRange',this.selectRange);
    console.log('this.distribution',this.distribution);
    if(this.selectRange === 'this_year') {
      this.selectRangeName = 'This Year';
    }else if(this.selectRange === 'this_month') {
      this.selectRangeName = 'This Month';
    }else if(this.selectRange === 'this_quarter') {
      this.selectRangeName = 'This Quarter';
    }else if(this.selectRange === 'all_time') {
      this.selectRangeName = 'All Time';
    }else if(this.selectRange === 'custom_date') {
      this.selectRangeName = `${this.dateObj.startDate}-${this.dateObj.endDate}`;
    }
    if (this.selectRange !== 'custom_date') {
      let requestBody = {
        "date_label": this.selectRange,
        "custom_start_date": this.selectRange == 'custom_date' ? this.dateObj.startDate : '',
        "custom_end_date": this.selectRange == 'custom_date' ? this.dateObj.endDate : '',
        "order_distributed_by":this.distribution
      }
      this.dashboardService.clientDashboardDetail(requestBody).subscribe(res => {
        console.log('clientDashboardDetail', res);
        this.clientData = res;
        if (this.clientData.production_summary.length > 0) {
          let orderReceivedArr = [];
          let orderCompletedArr = [];
          let orderCanceledArr = [];
          let productionSummaryLabel = [];
          this.clientData.production_summary.forEach(each => {
            orderReceivedArr.push(each.createdOrderCount);
            orderCompletedArr.push(each.completedOrderCount);
            orderCanceledArr.push(each.cancelledOrderCount);
            productionSummaryLabel.push(each.month)
          })
          let maxCount = orderReceivedArr.concat(orderCompletedArr, orderCanceledArr)
          let allValues = maxCount.map(ds => ds);
          this.maxProdSummaryValue = Math.max(...allValues);
          // this.productionSummaryData = [
          //   { data: orderReceivedArr, label: 'Received', backgroundColor: '#0D4C92' },
          //   { data: orderCompletedArr, label: 'Completed', backgroundColor: '#285430 ' },
          //   { data: orderCanceledArr, label: 'Canceled', backgroundColor: '#D23369' }
          // ];
          // this.barChartLabels = productionSummaryLabel;
          this.mbarChartLabels = productionSummaryLabel;
          this.barChartData =[
            { data: orderReceivedArr, label: 'Received' },
            { data: orderCompletedArr, label: 'Completed' },
            { data: orderCanceledArr, label: 'Canceled' },
          ]
        }
        if(this.clientData.order_distributed_data.length > 0) {
          let polarLabels = [];
          let polarData = [];
          this.polarAreaChartLabels = [];
          this.polarAreaChartData = [];
          this.clientData.order_distributed_data
          .forEach(each => {
            if(each.product_name && each.product_count) {
              polarLabels.push(each.product_name);
              polarData.push(each.product_count);
              this.polarAreaChartLabels = [...polarLabels]
              this.polarAreaChartData = [...polarData]
            }else if(each.state && each.stateCount) {
              polarLabels.push(each.state);
              polarData.push(each.stateCount);
              this.polarAreaChartLabels = [...polarLabels]
              this.polarAreaChartData = [...polarData]
            }else if(each.lenderCount && each.lender_name) {
              polarLabels.push(each.lender_name);
              polarData.push(each.lenderCount);
              this.polarAreaChartLabels = [...polarLabels]
              this.polarAreaChartData = [...polarData]
            }
          })
        }
        if(this.clientData.costCountInfo.length > 0) {
          this.totalCost = this.clientData.costCountInfo[0].TotalAmount;
          this.averageOrderCost = this.clientData.costCountInfo[0].averageOrderCost;
          this.completedOrders = this.clientData.costCountInfo[0].completedOrders;
        }
        if(this.clientData.totalCostOverTime.length > 0) {
          this.totalCostOverTimelineChartData[0].data = [];
          this.totalCostOverTimelineChartLabels = [];
          let arr = []
           if(requestBody.date_label == 'this_year' || requestBody.date_label == 'this_quarter' || requestBody.date_label == 'all_time') {
            this.clientData.totalCostOverTime.forEach(each => {
              arr.push(each.total)
             this.totalCostOverTimelineChartLabels.push(each.month);
             })
             this.totalCostOverTimelineChartData[0].data = [...arr];
           }else {
            this.clientData.totalCostOverTime.forEach(each => {
              arr.push(each.TotalCost)
             this.totalCostOverTimelineChartLabels.push(each.groupByDate);
             })
             this.totalCostOverTimelineChartData[0].data = [...arr];
           }        
        }
      })
    }
  }

  onChangeDate(data) {
    console.log('onChangeDate', data);
    if (data.startDate !== null && data.endDate !== null) {
      const startDate = (data.startDate['_d']).toLocaleDateString();
      const endDate = (data.endDate['_d']).toLocaleDateString();
      console.log('startDate', startDate);
      console.log('endDate', endDate);
      this.dateObj = { 'startDate': startDate, 'endDate': endDate }
      const dataName = 'custom_range';
      this.clientDashboardDetail();
    }
  }

}


