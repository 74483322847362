import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../services/orderService';
import OrderType from "../../services/orderTypeService";
import { NgxUiLoaderService } from "ngx-ui-loader";
import Swal from 'sweetalert2';
import { Config } from "../../config";
declare var jquery: any;
declare var $: any;

@Component({
    selector:'app-submissions',
    templateUrl:'./submissions.html',
    styleUrls:['./submissions.scss']
})

export class SubmissionsComponent{
    orderId: any;
    orderDetail: any = [];
    date_time: any;
    orderFulFillMentNonPlus: any = [];
    orderFulFillMentPlus: any = [];
    order_status: any;
    comments: any;
    configUrl = new Config().apiUrl
    showNonPlusFulfillment: boolean;
    showPlusFulfillment: boolean;

    constructor(private activatedRoute: ActivatedRoute, private orderService: OrderService,private router:Router,private ngxUiLoader:NgxUiLoaderService) {

    }
    ngOnInit() {
        // this.ngxUiLoader.start();
        this.activatedRoute.params.subscribe(p => {
            this.orderId = p.id
            this.getfulfillmentData(p.id);
        });

    }

    getfulfillmentData(orderId){
        this.ngxUiLoader.start();
        this.orderService.getOrderDetails(orderId).subscribe(data => {
            console.log("fetch fullfillment data : ",data)
            // this.date_time = data["updated_at"]
            this.orderDetail.push(data)
            this.order_status = data['status']
            console.log('111111111111111111111 ', this.order_status);
            this.showFulfillmentForm(data)
            this.ngxUiLoader.stop();
        })
        // console.log("ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo ",orderId)

        // this.orderService.getFullFillmentPlus(orderId).subscribe(data => {
        //     this.date_time = data["accepted_at"]
        //     this.comments = data['additionalInfo']
        //     this.orderFulFillMentPlus.push(data);
        //     // console.log("fetch plussssssssssssssssssssssssssssss ",this.orderFulFillMentPlus)
        //     this.ngxUiLoader.stop();

        // })

        // this.orderService.getFullFillmentNonPlus(orderId).subscribe(data => {
        //     // console.log("fetch non plussssssssssssssssssssssssssssss ",data)
        //     this.orderFulFillMentNonPlus = data;
        //     this.ngxUiLoader.stop();


        // })
    }

    fileDownloads(fileName){
        window.location.href = this.configUrl + '/file/read?fileName=' + fileName;
    }

    showFulfillmentForm(ordData) {
        console.log('Product Type : ', ordData.product_name);
        var product_name = ordData.product_name;
        var product_type = product_name.slice(-4);
        console.log('Last chr : ', product_type);
        if(product_type === 'Plus'){
            console.log("this is the IF IF IF IF FI IFFFFFFFFFFF block--------------------------")
            this.showNonPlusFulfillment = false
            this.showPlusFulfillment = true
        }else{
            console.log("this is the else block--------------------------")
            this.showNonPlusFulfillment = true
            this.showPlusFulfillment = false
        }

      }


}