import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../services/orderService';
import { Config } from '../config';
import { NgxUiLoaderService } from "ngx-ui-loader";
import Swal from 'sweetalert2';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-fulfillment-general-view',
  templateUrl: './fulfillment-general-view.component.html',
  styleUrls: ['./fulfillment-general-view.component.scss']
})
export class FulfillmentGeneralViewComponent implements OnInit {


  orderId: any;
  orderDetail: any = [];
  date_time: any;
  orderFulFillMentNonPlus: any = [];
  orderFulFillMentPlus: any = [];
  order_status: any;
  comments: any;
  configUrl = new Config().apiUrl
  showNonPlusFulfillment: boolean;
  showPlusFulfillment: boolean;
  totalUnit
  totalPrice
  order_no

  constructor(private activatedRoute: ActivatedRoute, private orderService: OrderService,private router:Router,private ngxUiLoader:NgxUiLoaderService) {
  this.totalUnit = 0
  this.totalPrice = 0
  }
  ngOnInit() {
    // this.ngxUiLoader.start();
    this.activatedRoute.params.subscribe(p => {
      this.orderId = p.id
      this.getfulfillmentData(p.id);
    });
  }

  getfulfillmentData(orderId){

    this.orderService.getOrderDetails(orderId).subscribe(data => {
      console.log("fetch fullfillment data : ",data)
      // this.date_time = data["updated_at"]
      this.orderDetail.push(data)
      this.order_status = data['status']
      this.order_no = data['nb_order_ref']
      let dd = data['op_order_ref']
      console.log("op_order_ref", dd)
      this.getNonPlusOrderDetails(data['nb_order_ref'])
      // this.showFulfillmentForm(data)
      this.ngxUiLoader.stop();
    })

    // this.orderService.getFullFillmentPlus(orderId).subscribe(data => {
    //   this.date_time = data["accepted_at"]
    //   this.comments = data['additionalInfo']
    //   this.orderFulFillMentPlus.push(data);
    //   console.log("fetch plussssssssssssssssssssssssssssss ",this.orderFulFillMentPlus)
    //   this.ngxUiLoader.stop();
    // })

  }

  getNonPlusOrderDetails(autoOrderRef) {     
    console.log('Order No : ', autoOrderRef)
    this.orderService.getFullFillmentNonPlus(autoOrderRef).subscribe(data => {
      console.log("fetch non plussssssssssssssssssssssssssssss ",data)
      this.orderFulFillMentNonPlus.push(data);
      console.log("fulll",this.orderFulFillMentNonPlus)     
      var pricing = data['pricingData'];
      console.log('&&&&&&&&&&&&&&& : ', pricing)
      console.log('&&&&&&&&&&&&&&&11111 : ', pricing.length)
      data['pricingData'] = [];
      for(let item of pricing ){
        if(item.priCostPerUnit != null){
          data['pricingData'].push(item)
          this.totalUnit = this.totalUnit + item.pricingUnit
          this.totalPrice = this.totalPrice + item.pricingTotal
        }
      }
      // for(let data of pricing){
      //   console.log("dataa",data)
      //   console.log("data",data.priCostPerUnit)
      //    this.totalUnit = this.totalUnit + data.pricingUnit
      //   this.totalPrice = this.totalPrice + data.pricingTota
      // }
      console.log('Unit : ',this.totalUnit)
      console.log('Unit : ',this.totalPrice)
      this.ngxUiLoader.stop();
    })
  }


  


  fileDownloads(fileName){
    window.location.href = this.configUrl + '/file/read?fileName=' + fileName;
  }

}
