import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { LoginService } from "./loginService";
declare var toastr:any

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router,private loginService: LoginService) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem("access_token");
    if (idToken) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization",
          idToken)          
      });
      this.loginService.infoFlag = true;
      return next.handle(cloned).pipe(
         catchError((error: HttpErrorResponse) => {
          let data = {};
          data = {
            reason: error && error.error.reason ? error.error.reason : '',
            status: error.status
          };
          if(error.status === 401 || error.status === 403) {
            this.loginService.infoFlag = false;
            this.router.navigate(['/login']);
            throw error;
          }else {
            throw error;
          }
        })
      )
    }
    else {
      this.loginService.infoFlag = false;
      this.router.navigate(['/login'])
      return
    }
  }
}