import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../services/loginService';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit  {
  islogin: any;
  
  constructor(public loginService: LoginService) { }

  ngOnInit() { 
    // this.loginService.changeName.subscribe(data=>{
    //   this.islogin = data;
    // });
    // this.loginService.infoFlag
   }


  logout(data) {
    this.islogin = data
  }

}
