import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  configUrl = new Config().apiUrl
  constructor(private http: HttpClient) { }

  orderStatusCount() {
    return this.http.get(this.configUrl + '/order/dashboard/orderStatusCount')
  }

  yearAndMonthList(obj) {
    return this.http.post(this.configUrl + '/order/dashboard/monthYear',obj)
  }

  dashboardChartsDetail(obj) {
    // let body = {
    //   "product_id":"",
    //   "state":"",
    //   "county":"",
    //   "status":"",
    //   "month":"6",
    //   "year":"2022"
    // }
    return this.http.post(this.configUrl + '/order/dashboard/products', obj)
  }

  clientDashboardDetail(obj) {
    return this.http.post(this.configUrl + '/order/client/dashboard', obj)
  }

}
