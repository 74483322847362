import { Component, AfterViewInit, ElementRef, ViewChildren, QueryList } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from "../../services/loginService";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthStorageService } from "../../services/auth-storage.service";
declare var toastr: any;


@Component({
    selector: 'app-otpvalidation',
    templateUrl: './otpvalidation.html',
    styleUrls: ['./otpvalidation.scss']
})
export class otpValidationComponent implements AfterViewInit {
    digitForm: FormGroup;
    @ViewChildren('digit') digits!: QueryList<ElementRef>;

    loginState: string;
    modalState: string;
    secret: string;
    login_username: string ;
    login_password: string ;
    emailSubscription:any;
    passwordSubscription:any;
    

    constructor(
        private loginService: LoginService, 
        private router: Router, 
        private route: ActivatedRoute, 
        private ngxuiloader: NgxUiLoaderService,
        private fb: FormBuilder,
        private authStorageService: AuthStorageService,
    ) {
        this.digitForm = this.fb.group({
            digit1: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
            digit2: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
            digit3: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
            digit4: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
            digit5: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
            digit6: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
        });
    }

    ngOnInit() {
        this.emailSubscription = this.authStorageService.getEmail().subscribe(email => {
            this.login_username = email;
        });

        this.passwordSubscription = this.authStorageService.getPassword().subscribe(password => {
            this.login_password = password;
        });

        this.ngxuiloader.stop();
        this.loginState = 'login';
        this.modalState = 'resetPwdModal';
        this.route.queryParams.subscribe(p => {
            this.secret = p.code;
        });

    }

    ngAfterViewInit() {
        this.setupAutoTab();
        this.preventNonNumericInput();
    }

    setupAutoTab() {
        this.digits.forEach((digit, index) => {
            digit.nativeElement.onkeyup = (e: KeyboardEvent) => {
                const target = e.target as HTMLInputElement;
                if (target.value.length === 1 && index < this.digits.length - 1) {
                    this.digits.toArray()[index + 1].nativeElement.focus();
                } else if (e.key === 'Backspace' && index > 0) {
                    this.digits.toArray()[index - 1].nativeElement.focus();
                }
            };
        });
    }

    preventNonNumericInput() {
        this.digits.forEach((digit) => {
            digit.nativeElement.onkeydown = (e: KeyboardEvent) => {
                if (!this.isNumericKey(e)) {
                    e.preventDefault();
                }
            };
        });
    }

    isNumericKey(e: KeyboardEvent): boolean {
        const key = e.key;
        return (key >= '0' && key <= '9') || key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight';
    }

    onSubmit() {

        if (this.digitForm.invalid) {
            toastr.error("Please enter a valid OTP");
            return;
        }

        const otp = `${this.digitForm.value.digit1}${this.digitForm.value.digit2}${this.digitForm.value.digit3}-${this.digitForm.value.digit4}${this.digitForm.value.digit5}${this.digitForm.value.digit6}`;
        const data = {
            email: this.login_username, 
            password: this.login_password,
            type: "client",
            otp: otp
        };
        console.log(data);  // Add this line
        this.verifyOtp(data);
    }

    verifyOtp(data: any) {
        this.ngxuiloader.start();
        this.loginService.verifyOtp(data).subscribe(obj => {
            if (obj.client.access) {
                toastr.success("OTP validated successfully");
                window.localStorage.setItem('access_token', obj.client.access_token);
                this.router.navigate(['/myOrder']);
            } else {
                if(obj.client.msg == "Invalid OTP, your account is blocked. Please contact administrator."){
                    toastr.error(obj.client.msg);
                    this.ngxuiloader.stop();
                    this.router.navigate(['/login']);
                }
                else{
                    toastr.error(obj.client.msg);
                    this.ngxuiloader.stop();
                }                
            }
        }, error => {
            toastr.error("Something went wrong");
            this.ngxuiloader.stop();
        });
    }
}