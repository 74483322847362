import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { LoginService } from "../../services/loginService";
declare var $:any
declare var toastr:any
@Component({
    selector:'app-reset-password',
    templateUrl:'./reset-password.html',
    styleUrls:['./reset-password.scss']
})

export class ResetPasswordComponent{
    new_password
    verify_password
    secret
    loginState

    constructor(private loginService:LoginService,private route:ActivatedRoute){
        this.route.queryParams.subscribe(p=>{
            this.secret=p.code
        })
    }

    ngOnInit(){
        this.loginState = 'resetPassword'
    }

    onSubmit(form:NgForm){
        if(this.new_password === this.verify_password){
            const data = {
                password : this.new_password,
                code:this.secret
            }
            this.resetPassword(data)
        }
        else{
            toastr.error("Passwords do not match")
        }
        
    }

    resetPassword(data){
        this.loginService.resetPassword(data).subscribe(obj=>{
            this.loginState = 'reset-success-msg'
        },error => {
            toastr.error("Something went wrong")
            console.log("Password Reset Failed",error)
        })
    }

}