import { Component,OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OrderService } from '../../services/orderService';
import Swal from "sweetalert2";
import { Config } from "../../config";
declare var toastr: any;
import date from 'date-and-time';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe } from "@angular/common";

@Component({
    selector:'app-files',
    templateUrl:'./files.html',
    styleUrls:['./files.scss']
})

export class FilesComponent implements OnInit{

    orderFiles:Array<any>
    orderCreatedAt
    clientstatusData:any = ''
    status_flag = false
    statusDetail:any = ''
    showLoader
    fileDetails
    orderID
    configUrl = new Config().apiUrl
    currentDate: any;
    clientId: any;
    OperaionOrderFiles:Array<any>
    ClientOrderFiles:Array<any>
    fileArray: any = []
    nb_order_ref: any;
    constructor(private activatedRoute: ActivatedRoute, private orderService: OrderService, private ngxUILoader: NgxUiLoaderService) {
        this.orderFiles = []
        this.fileDetails = []
        this.OperaionOrderFiles = []
        this.ClientOrderFiles = []
        this.fileArray = []
        this.dateTimeValue()
    }

    ngOnInit(){

        this.activatedRoute.params.subscribe(p=>{
            this.getOrderDetails(p.id)
        })

        this.activatedRoute.params.subscribe(obj => {
            this.getOrderFilesData(obj.id);
            this.orderID = obj.id
        })
    }

    getOrderDetails(orderID){
        console.log("idddd",orderID)
        this.orderService.getOrderDetails(orderID).subscribe(data =>{
            console.log("orderDatafilecomponent", data)
             this.statusDetail = data['status'];
              //console.log("deeeee",this.statusDetail)
             if(this.statusDetail == "OperationSubmitted" || this.statusDetail== "Completed" ){
                this.status_flag  = true
            }
            else{
                this.status_flag  = false
            }          
    })    
    }

    dateTimeValue() {
        const now = new Date()
        var utcDate = date.format(now, 'YYYY-MM-DD HH:mm:ss', true);
        utcDate = new Date(utcDate);
        utcDate.setHours(utcDate.getHours() - 5)
        this.currentDate = utcDate
    }

    getOrderFilesData(orderID) {
        this.ngxUILoader.start();
        this.orderService.getOrderDetails(orderID).subscribe((data:any) => {
            this.orderFiles = data.fileLocation?data.fileLocation:[0];
            this.orderCreatedAt = data.created_at
            this.clientId = data.client_unique_id
            const client_id = data.client_unique_id
            this.nb_order_ref = data.nb_order_ref ? data.nb_order_ref : data.op_order_ref
            this.getAllOperationuploadedFile()
            this.getAllClientUploadedFile()
            this.ngxUILoader.stop();
            // console.log('Created date : ', this.orderCreatedAt)
        });
        
    }

    fileDownloads(fileName){
        window.location.href = this.configUrl + '/file/read?fileName=' + fileName;
    }

    uploadFiles(event) {
        this.showLoader = true;
        this.ngxUILoader.start();
        const newFile = event.files[0];
        const fileReader = new FileReader();

        fileReader.readAsArrayBuffer(newFile);
        this.orderService.fileUpload(newFile).subscribe(obj => {
            var datePipe = new DatePipe("en-US");
            const fileDataobj = {
                file_name: obj['key'],
                file_sender: "client",
                file_sender_id: this.clientId,
                created_at: datePipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss'),
                updated: datePipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss')
            }
            const fileId = {
                order_id: this.orderID,
                client_id: this.clientId,
                fileData: [fileDataobj]
            }   
            console.log('File Upload : ',  fileId)
            this.orderService.updateOrderFiles( fileId).subscribe(data => {
                this.orderFiles.push(data);
                this.getOrderFilesData(this.orderID)
                toastr.success("File uploaded successfully")
                this.showLoader = false;
                this.ngxUILoader.stop();
            })
        },
        error => {
            toastr.error("Somethiing went wrong")
        });
    }

    deleteFile(i,file_name) {
        const fileData = {
            delete_file_name: file_name,
            order_id: this.orderID,
            client_id: this.clientId
        }

        Swal.fire({
            imageUrl: '/assets/warning-sweet-alert.svg',
            imageWidth: 57,
            imageHeight: 57,
            title: 'Are you sure?',
            text: "You want to delete this file?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00A8E8',
            cancelButtonColor: '#F4F5F8',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
               
                this.orderService.deleteOrderFiles(fileData).subscribe(data =>{
                    // this.ngxUILoader.start();
                    // console.log(data['data'].msg)
                    toastr.success("File deleted successfully");
                    this.getOrderFilesData(this.orderID)
                    this.getAllOperationuploadedFile()
                    this.getAllClientUploadedFile()
                    this.ngxUILoader.stop();                    
                })
            }
        })        
    }

    getAllOperationuploadedFile(){
        const data = {
            client_id: this.clientId,
            order_id: this.orderID,
            file_sender: 'operation',
            nb_order_ref: this.nb_order_ref
        }
        // console.log('All id : ', data)
        this.orderService.getAllFiles(data).subscribe((res:any) => {
            // console.log('All Client file 1: ', res)
            if (res.length !=0 ){
                this.OperaionOrderFiles = res[0]['fileArray']
                console.log('data...',this.OperaionOrderFiles)
                for(let item of this.OperaionOrderFiles)
                console.log("it...",item.file_name)  
            }            
            // console.log('All Operation file : ', this.OperaionOrderFiles)
            this.ngxUILoader.stop();
        })
    }

    getAllClientUploadedFile(){
        const data = {
            client_id: this.clientId,
            order_id: this.orderID,
            file_sender: 'client',
            nb_order_ref: this.nb_order_ref
        }
        this.orderService.getAllFiles(data).subscribe((res:any) => {
            // console.log('All Client file 2: ', res)
            if(res.length != 0){
                this.ClientOrderFiles = res[0]['fileArray']
            }            
            
            this.ngxUILoader.stop();
        })
    }
    // clientstatusinfo(event){
    //     console.log("status..",event)
    //     console.log("aaaaa",event.status)
    //     this.statusDetail = event.status
    //     if(this.statusDetail == "OperationSubmitted" || this.statusDetail== "Completed" ){
    //         this.status_flag  = true
    //     }
    //     else{
    //         this.status_flag  = false
    //     }
    // }

}

