import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onPrivacyPolicy() {
    this.router.navigate(['/privacyPolicy'])
  }
  onTermsAndCoditions() {
    this.router.navigate(['/termsConditions'])
  }

}
