import { Component } from "@angular/core";
declare const $
@Component({
    templateUrl:'./order-detail.html',
    styleUrls:['./order-detail.scss']
})
export class orderDetailPageComponent{

   constructor(){
   }

   

}