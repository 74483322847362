import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../services/orderService';
import { Config } from '../config';
import { NgxUiLoaderService } from "ngx-ui-loader";
import Swal from 'sweetalert2';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-fulfillment-plus-type-view',  
  templateUrl: './fulfillment-plus-type-view.component.html',
  styleUrls: ['./fulfillment-plus-type-view.component.scss']
})
export class FulfillmentPlusTypeViewComponent implements OnInit {

  orderId: any;
  orderDetail: any = [];
  date_time: any;
  orderFulFillMentNonPlus: any = [];
  orderFulFillMentPlus: any = [];
  order_status: any;
  comments: any;
  configUrl = new Config().apiUrl
  showNonPlusFulfillment: boolean;
  showPlusFulfillment: boolean;
  autoOrderNo: any;

  constructor(private activatedRoute: ActivatedRoute, private orderService: OrderService,private router:Router,private ngxUiLoader:NgxUiLoaderService) {
  }
  ngOnInit() {
    // this.ngxUiLoader.start();
    this.activatedRoute.params.subscribe(p => {
      this.orderId = p.id
      this.getfulfillmentData(p.id);
    });
  }

  getfulfillmentData(orderId){
    this.orderService.getOrderDetails(orderId).subscribe(data => {
      console.log("fetch fullfillment data : ",data)

      this.autoOrderNo = data['nb_order_ref'] == null?data['op_order_ref']:data['nb_order_ref']
      // this.date_time = data["updated_at"]
      this.getAllFulFillMentPlus(this.autoOrderNo)
      this.orderDetail.push(data)
      this.order_status = this.autoOrderNo
      // this.showFulfillmentForm(data)
      this.ngxUiLoader.stop();
    })


  }

  getAllFulFillMentPlus(autoOrderNumber) {
    console.log("auto order number ==== ",autoOrderNumber)
    this.orderService.getFullFillmentPlus(autoOrderNumber).subscribe(data => {
      this.orderFulFillMentPlus.push(data);
      console.log("fetch plussssssssssssssssssssssssssssss ",this.orderFulFillMentPlus)
      for(let item of this.orderFulFillMentPlus){
        console.log("1111111111111",item.this.orderFulFillMentPlus);
        
      }

      this.ngxUiLoader.stop();
    })

  }

  fileDownloads(fileName){
    window.location.href = this.configUrl + '/file/read?fileName=' + fileName;
  }

}
